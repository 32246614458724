import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'
import React, { useContext, useEffect } from 'react';
import { matchPath } from "react-router";
import { imageUpload, videoUpload, handleContinueEmpty } from '../helpers/index';
import AppContext from "../utils/app-context";
import { AuthContext } from "../utils/auth-provider";
import firebase from '../utils/firebase';
import { useDropzone } from 'react-dropzone'
import { nanoid } from "nanoid";
import toast from 'react-hot-toast';
import assets from "../utils/assets"
import { useHistory } from "react-router-dom";

const DropUpload = (props) => {
    const { type = "default", children, dropFunc, mediaRestrict, mode = "take", status = "", portalId, callback } = props
    const { state, dispatch } = useContext(AppContext);
    const loc = window.location;
    const { currentUser } = useContext(AuthContext);
    const [vidFile, setVidFile] = React.useState(null);
    let path = null
    if (mode === 'shot') {
        path = matchPath(loc.pathname, {
            path: '/p/:id',
            exact: false,
            string: false
        })
    } else {
        path = matchPath(loc.pathname, {
            path: '/p/:id/:shotID',
            exact: false,
            string: false
        })
    }
    const history = useHistory();
    const currentShotId = (path?.params?.shotID) ? path.params.shotID : null;
    const storyImport = (props.type.indexOf("story-import") != -1 ? true : false)
    const newStory = (storyImport && props.type.indexOf("-new") != -1) ? true : false;
    const addItemStyles = {
        height: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
        maxWidth: "250px",
        minWidth: "150px",
        margin: "0 auto",
        minHeight: "200px"
      };
    if (mode === "take") {
        addItemStyles.minWidth = "130px"; 
        addItemStyles.minHeight = "146px";
      }
    function capWord(word) {
        return word[0].toUpperCase() + word.substring(1);
    }

    const uploadVideo = async () => {
        videoUpload({ file: vidFile, shot_id: currentShotId, state: state, storyImport: storyImport, newStory:newStory, callback: callback, dispatch: dispatch, currentUser: currentUser, firebase: firebase })
        setVidFile(null);
    }

    useEffect(() => {
        console.log("import", storyImport);
        console.dir("vid", vidFile);
        console.dir("activestory", state.stories.activeStory)
        if(storyImport && vidFile && state.stories.activeStory) {
            if(newStory) history.push("/p/" + state.stories.activeStory.portal_id);
            uploadVideo();
        }
    }, [vidFile]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        noDragEventsBubbling: true,
        onDrop: (acceptedFiles) => {
            if (mode === 'shot') {
                acceptedFiles.forEach((file) => {
                    let newShotId = nanoid()
                        if (file.type.indexOf("image") != -1)
                            imageUpload({
                              file: file,
                              shot_id: newShotId,
                              state: state,
                              dispatch: dispatch,
                              currentUser: currentUser,
                              firebase: firebase,
                              path: path,
                            }).then(() => {
                                addEmptyShot(newShotId)
                            }).catch(() => {
                                toast.error('error adding shot')
                            })
                          else if (file.type.indexOf("video") != -1)
                            videoUpload({
                              file: file,
                              shot_id: newShotId,
                              state: state,
                              dispatch: dispatch,
                              currentUser: currentUser,
                              firebase: firebase,
                            }).then(() => {
                                addEmptyShot(newShotId)
                            }).catch(() => {
                                toast.error('error adding shot')
                            })
                          else toast.error("File type not compatible.");
                })
            } else {
                console.dir("portal2", props.portalId);
                if (dropFunc) dropFunc();
                acceptedFiles.forEach((file) => {
                    if (file.type.indexOf('image') != -1 && mediaRestrict != "video") imageUpload({ file: file, shot_id: currentShotId, state: state, dispatch: dispatch, currentUser: currentUser, firebase: firebase, path: path })
                    else if (file.type.indexOf('video') != -1) {
                        if(storyImport) {
                            setVidFile(file);
                            if(newStory) handleContinueEmpty({dispatch:dispatch, portalId:props.portalId});
                            // else uploadVideo();                            
                        } else {
                            videoUpload({ file: file, shot_id: currentShotId, state: state, storyImport: storyImport, portalId: props.portalId, callback: callback, dispatch: dispatch, currentUser: currentUser, firebase: firebase })
                        }
                    } else alert('File type not compatible.')
                })
            }

        },
        onDragEnter: (e) => {
            e.preventDefault();
            e.stopPropagation();
        },
        onDragLeave: (e) => {
            e.preventDefault();
            e.stopPropagation();
        },
        maxFiles: 10,
        onDropRejected: () => {
            alert("You cannot upload more then 10 files.")
        }
    })

    const storyStyles = {
        width: "100%",
        height: "150px",
        border: "2px dashed rgba(0, 0, 0, 0.15)",
        transition: "all .5s ease-in-out",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        "&:hover": {
            background: "rgba(0, 0, 0, 0.15)"
        },
        background: (isDragActive) ? "rgba(0, 0, 0, 0.15)" : "transparent"
    }

    const overlayStyles = {
        display: isDragActive ? 'block' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(39, 39, 39, 0.8)',
        color: 'white',
        zIndex: 999999,
        textAlign: 'center',
        fontSize: '20px',
        lineHeight: '100vh',
        pointerEvents: 'none',
        fontWeight: 600
    }

    const onFileUploadChangeMobile = async (event) => {
        let files = await event.target.files;

        Array.from(files).forEach(file => {
            let newShotId = nanoid()
            if (mode === 'shot') {
                videoUpload({
                  file: file,
                  shot_id: newShotId,
                  state: state,
                  storyImport: storyImport,
                  currentUser: currentUser,
                  dispatch: dispatch,
                  firebase: firebase,
                }).then(() => {
                    addEmptyShot(newShotId)
                }).catch(() => {
                    toast.error('error adding shot')
                })
            } else {
                videoUpload({ file: file, shot_id: currentShotId, state: state, storyImport: storyImport, portalId: portalId, callback: callback, currentUser: currentUser, dispatch: dispatch, firebase: firebase });
            }

        })
    }

    const addEmptyShot = async (shotID) => {
        let part_id = props.part_id
        let description = props.next_shot_index + ":"
        let newShotId = shotID ? shotID : nanoid()
        const random_int = Math.floor((Math.random() * 18))
        const biased_int = [0, 0, 1, 1, 1, 1, 2, 2, 3, 3, 5, 5, 6, 6, 7, 7, 8, 8, 0][random_int]
        const shot_type_filenames = ["wide.png", "mid.png", "action.png", "close.png", "empty.png", "xcu.png", "two_shot.png", "ots.png", "full.png", "action_close.png",]
        const shot_type = shot_type_filenames[biased_int]
        const random_image = assets["empty_thumbnail"];
        // const random_image = `https://storage.googleapis.com/v0zebra.appspot.com/resource/shot_type/${shot_type}`
        const new_panel_desc = ['Wide shot', 'Mid shot', 'Action shot', 'Close up of face', '', 'Extreme close up', 'Two-Shot', 'Over the shoulder', 'Full shot', 'Action Close up'][biased_int]
        const one_word_title = description.substring(description.lastIndexOf(" ") + 1)
        const punctuation_free_title = one_word_title.replace(/[".,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        const capitalized_title = punctuation_free_title[0].toUpperCase() + punctuation_free_title.substring(1);
        const newShot = {
            id: newShotId,
            header: capitalized_title,
            image: random_image,
            meta: "",
            prompt: "",
            description: new_panel_desc,
            last_take_id: null,
            selected_take_id: null,
            last_take_im: null,
            last_take_stream_url: null,
            deleted_at: null
        };
        const payload = {
            part_id: part_id,
            shot: newShot, 
            shot_id: newShot.id
        }
        dispatch({ type: "ADD_STORY_SHOT", payload: payload });
    }

    const draggableChildren = React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a
        // typescript error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...getRootProps({ onClick: e => e.stopPropagation() }) });
        }
        return child;
    });

    let addText = (mode == "shot") ? "Add Media Shot" : "Add Take"
    let addEmptyStyles = {
        height:"50%", width:"100%", borderBottom:"1px solid #ccc", cursor:"pointer", 
        display:"flex", 
        flexFlow:"column", 
        alignItems:"center", 
        justifyContent:"center", 
        borderTop: '2px solid transparent',
        borderLeft: '2px solid transparent',
        borderRight: '2px solid transparent',
        borderBottom: "2px solid rgba(0, 0, 0, 0.15)", 
        '&:hover': {
            border: '2px solid rgba(0, 0, 0, 0.15)',
        }
    }
    let addMediaStyles = {
        height:"50%", 
        width:"100%",  
        cursor:"pointer", 
        display:"flex", 
        flexFlow:"column", 
        alignItems:"center", 
        justifyContent:"center", 
        border:"2px solid transparent", 
        '&:hover': {
            border: '2px dashed rgba(0, 0, 0, 0.15)',
            '.drop': {
                display: 'block'
            }
        },
    }
    let addMobileStyles = {
        height:"50%", 
        width:"100%",  
        minWidth:"100%", 
        cursor:"pointer", 
        display:"flex", 
        flexFlow:"column", 
        alignItems:"center", 
        justifyContent:"center", 
    }
    if(mode == "take") addMediaStyles.height = "100%"
    if(mode == "take") addMobileStyles.height = "100%"

    const mobileAdd = 
        <Box sx={addMobileStyles}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                <label href="#" className="image_wrapper_label image-file-upload" style={{ border: "none", textAlign: "center", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: "100%", color: "#272727" }}>
                    <input value={null} type="file" onChange={onFileUploadChangeMobile} accept="video/*" capture="environment" hidden />
                    <PhotoCameraIcon style={{ fontSize: "45px" }} />
                </label>
                <label href="#" className="image_wrapper_label image-file-upload" style={{ border: "none", textAlign: "center", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: "100%", color: "#272727" }}>
                    <input value={null} type="file" onChange={onFileUploadChangeMobile} accept="video/*" multiple />
                    <FileUploadIcon style={{ fontSize: "45px" }} />
                </label>
            </Box>
            <Typography style={{ textAlign: "center", marginBottom: '10px' }}>{addText}</Typography>
        </Box>

    return (
        <>
            <input style={{
                display: 'block'
            }} {...getInputProps()} />
            {storyImport &&
                <Box {...getRootProps()} sx={storyStyles}>
                    Drop media or click to upload
                </Box>
            }
            {!storyImport && <Box {...getRootProps()} style={overlayStyles} ref={props.fileUploadRef}  >
                Drop Media to import as {capWord(mode)}
            </Box>}
            {!storyImport && type == "add" && 
            <>
                <Grid item {...(status != "empty" ? {xs:6, sm:4, md:3, lg:2} : {})}>
                    <Item sx={addItemStyles}>
                        {mode == "shot" &&
                            
                                    <Box sx={addEmptyStyles} onClick={() => addEmptyShot()}>
                                        <AddIcon style={{ fontSize: "45px" }} />
                                        <Typography>Add Empty Shot</Typography>
                                    </Box>
                        }  
                        {!state.app.isMobile && 
                                <Box {...getRootProps()} sx={addMediaStyles}>
                                    <AddIcon style={{ fontSize: "45px" }} />
                                    <Typography>{addText}</Typography>
                                    <Typography className="drop" sx={{ display: "none" }}>(Or Drop Media)</Typography>
                                </Box>
                        }
                        {state.app.isMobile && !storyImport && type == "add" &&
                            mobileAdd
                        }
                    </Item>
                </Grid>  
            </>                                         
            }
            {!storyImport && type == "wrap" &&
                <>
                    {draggableChildren}
                </>
            }
        </>
    )
}

export default DropUpload;