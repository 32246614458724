import "../components/pmm.css";
//const loc = window.location;
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Icon,
  Input,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  MenuList,
  OutlinedInput,
  Select,
  Slider,
  SvgIcon,
  Tab,
  Tooltip, 
  Typography,
  isWidthUp,
  useMediaQuery,
} from "@mui/material";
import {
  CheckBox, 
  CheckBoxOutlineBlank, 
  VolumeUp, 
  VolumeOff, 
  ArrowDropDown, 
  KeyboardDoubleArrowRight, 
  KeyboardDoubleArrowLeft, 
  ArrowBackIos, 
  ArrowForwardIos, 
  Close, 
  ContentCopy, 
  Delete, 
  Download, 
  FileUpload, 
  FitScreen, 
  MoreHoriz, 
  MovieFilter, 
  OpenWith, 
  PhotoCamera, 
 } from "@mui/icons-material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import AlertUploadStatus from "./alert-upload-status";
import AppContext from "../utils/app-context";
import { TabList, TabContext, TabPanel } from '@mui/lab';
import AuthBox from "./auth-box";
import { AuthContext } from "../utils/auth-provider";
import DropUpload from "./drop-upload";
import Footbar from "./Footbar";
import Headbar from "./Headbar";
import Messages from "../constants/app";
import ReactPlayer from "react-player";
import RecordableShotGroup from "../components/recordable-shot-group";
//import SelectIcon from "../images/icon-select.svg";
import ShotGroup from "../components/shot-group";
import ViewToggle from "./toggle-view";
import ZebraPlayer from "./zebra-player";
import hostConfig from "../utils/config";
import toast from "react-hot-toast";
import ShareDialog from "./share-dialog";
//import {computeSequence, computePercentFilmed} from '../utils/sequence.js';
//import navLogo from '../images/nav-logo.svg'
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'
import { matchPath } from "react-router";
import { nanoid } from "nanoid";
import theme from '../styles/theme';

import assets from "../utils/assets";
import firebase, { storage } from '../utils/firebase';
import { collection, addDoc, setDoc, doc, serverTimestamp } from "@firebase/firestore"
import { ref, getDownloadURL } from "@firebase/storage"
import GenericSelect from "./generic/GenericSelect";
import TakesPreviewDialog from "./TakesPreviewDialog";
import { sortTakes } from "../utils/utility";
import { debounce } from "../helpers";

const init_shots = [{
    header: 'Sample Shot',
    description: 'A short descrition so contributors know what to film.  Like "People having fun as they wait in line to enter"',
    meta: "Outside the venue",
    image: "https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb001a.jpg",
  },
];

const clearStyles = {
  textDecoration:'none', 
  border:'1px solid black', 
  textAlign:'center', 
  borderRadius:'50%', 
  width:'20px', 
  height:'20px', 
  lineHeight:'15px', 
  marginLeft:'10px', 
  opacity: 0.5,
  transition: 'opacity 0.5s ease-in-out', 
  '&:hover': {
    cursor: 'pointer',
    background: 'black',
    color: 'white',
  },
  '&.hidden': {
    opacity: 0,
    visibility: 'hidden',
  },
  '&.visible': {
    opacity: 1,
    visibility: 'visible',
  }
}

const sectionStyles = {
  margin: "0 0 1.5em",
  "legend > span": {
    opacity: 100,
    position: "relative",
    top: "-4px",
  },
};

const headNavStyles = {
  padding: "1em",
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
};
const subNavStyles = {
  padding: "1em",
  borderBottom: "1px solid " + theme.palette.primary.main,
  display: "flex",
  position: "relative",
  marginBottom: "2em",
  alignItems: "center",
};

const emptyStyles = {
  margin: "0 1em",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%", //"calc(16.66666667% - 1.5em)",
  margin: "0 .75em",
  "#reimagine_cont": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "middle",
  },
  "&.empty": {
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    //width:"calc(33.33333333% - 2em)",
    margin: "0 1em",
    "#reimagine_cont": {
      justifyContent: "center",
      h3: {
        textAlign: "center",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    "#reimagine_cont": {
      margin: "0 auto",
    },
  },
  [theme.breakpoints.only("xs")]: {
    //width:"calc(50% - 2em)",
    margin: "0 1em",
  },
};

const selectedWrapStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  "&.selected-wrap > div": {
    justifyContent: "flex-start",
    " > div, > h2": {
      width: "100%",
    },
  },
  "&.empty-wrap": {
    justifyContent: "space-around",
    " > div, > .reimagine": {
      width: "50%",
      display: "flex",
      justifyContent: "center",
    },
    " > div > div": {
      width: "100%",
      justifyContent: "center",
    },
  },
  [theme.breakpoints.down("md")]: {
    padding: "0",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    ".selected": {
      marginBottom: "1.5em",
    },
    "&.empty-wrap #reimagine_cont span": {
      display: "block",
    },
    ".reimagine": {
      width: "100%",
      marginBottom: "4em",
      "#reimagine_cont": {
        margin: "0 auto",
      },
    },
    "&.selected-wrap .reimagine": {
      marginBottom: "2em",
    },
  },
};

const wrapStyles = {
  //minHeight:'60vh',
  padding: "3em",
  [theme.breakpoints.down("md")]: {
    padding: "1.5em",
  },
};

const selectedStyles = {
  maxWidth: "1600px",
  width: "60%",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    //padding:"1em 0"
  },
  //width: "100%" //"calc(100% - 64px)"
};

const takesStyles = {
  padding: "1em 0",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    //padding:"1em 0"
  },
};
const noSelectedTakesStyles = {
  padding: "1em 0",
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    //padding:"1em 0"
  },
};

const iconStyles = {
  fontSize: "20px",
  lineHeight: "24px",
  marginRight: "5px",
  position: "relative",
  color: theme.palette.secondary.dark,
};
const wandStyles = {
  fontSize: "20px",
  lineHeight: "24px",
  marginRight: "5px",
  position: "relative",
  filter: "brightness(0) saturate(100%) invert(10%) sepia(0%) hue-rotate(218deg) contrast(87%)",
};

const orStyles = {
  display: "none",
  textAlign: "center",
  marginBottom: "2em",
};

const successStyles = {
  position: "absolute",
  top: "20px",
  left: "110px",
  fontSize: "9px",
  lineHeight: "12px",
  textTransform: "lowercase",
  transition: ".5s all",
};

const successSelectedStyles = {
  position: "absolute",
  bottom: "-15px",
  left: "0px",
  fontSize: "9px",
  lineHeight: "12px",
  textTransform: "lowercase",
  transition: ".5s all",
};

const sliderStyles = {
  margin: "0 0 1.5em",
  ".MuiInputLabel-shrink": {
    padding: "0 5px",
  },
  ".slider-wrap": {
    padding: "0 25px",
    height: "0px",
    overflow: "hidden",
    transition: ".5s all",
  },
  ".arrow-drop": {
    fontSize: "25px",
    position: "relative",
    transition: ".5s all",
    verticalAlign: "middle",
  },
  ".MuiSlider-markLabel": {
    fontSize: ".6em",
    top: "24px",
  },
  "&.open": {
    ".arrow-drop": {
      transform: "rotate(180deg)",
    },
    ".slider-wrap": {
      height: "auto",
      padding: "10px 25px",
    },
  },
  "&:hover": {
    ".arrow-drop, #form-prompt-strength-label": {
      color: theme.palette.secondary.dark,
      cursor: "pointer",
    },
  },
  [theme.breakpoints.down("sm")]: {
    ".MuiSlider-markLabel": {
      top: "36px",
    },
  },
};


function Takes(props) {
  const { state, dispatch } = useContext(AppContext);

  const volume = state.app.userIsStoryOwner || (!state.app.userIsStoryOwner && state.app.settings.communityCanListenToTakes) ? 1 : 0;

  const [displayImages, setDisplayImages] = useState(true);
  const [displayShare, setDisplayShare] = useState(true);
  const [viewLatestTake, setViewLatestTake] = useState(true);
  const [showModal, setShowModal] = useState(false);
  //const [viewPreview, setViewPreview] = useState(false);
  const [selectedShot, setSelectedShot] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedReimagineItem, setSelectedReimagineItem] = React.useState(null);
  const [selectedShotAction, setSelectedShotAction] = React.useState(null);
  const [selectedPreview, setSelectedPreview] = React.useState(null);
  const [moveModalOpen, setMoveModalOpen] = React.useState(null);
  const [compAlertOpen, setCompAlertOpen] = React.useState(null);
  const [tabValue, setTabValue] = React.useState("new");
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [showSlider, setShowSlider] = React.useState(false);
  const [showReimagine, setShowReimagine] = React.useState(false);
  const [showAuth, setShowAuth] = React.useState(false);
  const [authFunc, setAuthFunc] = React.useState(null);
  const [redirectLoc, setRedirectLoc] = React.useState(null);
  const [prompt, setPrompt] = React.useState("");
  const [strength, setStrength] = React.useState(3);
  const [promptValid, setPromptValid] = React.useState(true);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showSelectedSuccess, setShowSelectedSuccess] = React.useState(false);
  const [takeMenus, setTakeMenus] = React.useState({});
  const [showVid, setShowVid] = useState({});
  const [vidPlay, setVidPlay] = useState({});
  const [vidHover, setVidHover] = useState({});
  const [playTake, setPlayTake] = useState({});
  const [playMain, setPlayMain] = useState(false);
  const [mainHover, setMainHover] = useState(false);
  const [moveTake, setMoveTake] = useState(null);
  const [moveToShot, setMoveToShot] = useState(null);

  const [deletingTake, setDeletingTake] = useState(null);
  const [moveTakeShot, setMoveTakeShot] = useState(null);
  const [takes, setTakes] = useState([]);
  const [takesCount, setTakesCount] = React.useState(null);
  const [takesList, setTakesList] = React.useState([]);
  const [reimagineDefaults, setReimagineDefaults] = React.useState(null)


  const { currentUser } = useContext(AuthContext);
  const [selectedMethod, setSelectedMethod] = useState("text2vid");
  const [modelDefault, setModelDefault] = useState(selectedMethod == "text2vid" ? "text2vid-fluma" : "luma-image");
  const [chosenModel, setChosenModel] = useState(null);

  const [isPromptCleared, setIsPromptCleared] = useState(false)
  const [activeShotIndex, setActiveShotIndex] = useState(0);
  const [showShareModal, setShowShareModal] = useState(false); 
  const [takeMenu, setTakeMenu] = React.useState(null);
  const location = useLocation();
  
  const [cost_per_veneer, setCostPerVeneer] = useState(100); 
  const [anchorEl, setAnchorEl] = useState(null);
  const medScreen = (useMediaQuery(theme.breakpoints.down("md"))) ? true : false
  const open = Boolean(anchorEl);
  const dynamicUploadStyles = {
    position: 'fixed',
    bottom: medScreen ? '90px' : '60px',
    left: medScreen ? '50%':'40%',
    transform: 'translateX(-50%)',
    whiteSpace: 'nowrap',
    width: 'auto',
    maxWidth: '100%',
    zIndex:'99'
  };

  const handleClick = (event, take) => {
    setTakeMenu(take.stream_path === undefined ? loadingMenu(take) : fullMenu(take));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTakesMenu = (take) => {
    if(take.streampath) return fullMenu(take)
      else return (loadingMenu(take))
  }

  
  const allTakes = state.stories.activeTakeList;
  const strip = (location.search.indexOf('strip') != -1) ? true : false;
     
  const history = useHistory();
  const defaultPrompt = "Cinematic closeup of a Canadian crossfit trainer with a rugged backpack, hiking through the Yucatan jungle at night, illustration style";

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const pathSegments = location.pathname.split('/');
      const newShotId = pathSegments[pathSegments.length - 1];

      if (newShotId) {
        const filteredTakes = allTakes.filter(take => take.shot_id === newShotId)
        setTakesList(sortTakes(filteredTakes));
      } else {
        setTakesList(allTakes);
      }
    });

    return () => {
      unlisten();
    };
  }, [history, state.stories.activeTakeList, allTakes]);

  const models = hostConfig.aiModels;
  const strengthMap = ["Subtle", "Reduced", "Normal", "Increased", "Strong"];
  //if (!chosenModel) setChosenModel(modelDefault);

  //const loc = window?.location;
  //let params = new URLSearchParams(loc.search)
  let shot_id = null;
  let portal_id = state.stories.activeStory.portal_id ? state.stories.activeStory.portal_id : state.app.portal.id;
  let nextPart = null;
  let storyPart = null;
  

  let previewVolume = state.app.previewVolume;
  let prev = {};
  let next = {};
  let path = matchPath(location?.pathname, {
    path: "/p/:id/:shotID/",
    exact: false,
    string: false,
  });

  if (path?.params?.shotID) {
    shot_id = path.params.shotID;
    portal_id = !portal_id ? path.params.id : portal_id;
  }
  let activeShotId = shot_id; // this is all undeleted shots
  //const classes = styles;

  const story = state.stories.activeStory; // Getting active story
  const activeShotList = state.stories.activeShotList; // Getting all undeleted shots

  const orderArray = story.parts ? story.parts[0].order : [];

  const shots = [...activeShotList]?.sort((a, b) => {

    const indexA = orderArray.indexOf(a.id);
    const indexB = orderArray.indexOf(b.id);
  
  
    if (indexA === -1 || indexB === -1) {
      return indexA === -1 ? 1 : -1;
    }

    return indexA - indexB;
  });
  
  const isItemFromActiveShot = (item) => item.shot_id === activeShotId;

  const veneerLUT = state.stories.activeVeneerLUT;

  const isLoggedIn = Object.keys(state.userData).length != 0 ? true : false;

  let shot = null;
  let shot_key = 1;
  let takes_group = null;
  let selected_take = null;
  //let takes_list = (takesCount == null) ? [] : takesList
  const random_int = Math.floor(Math.random() * 18);
  const random_image = `https://storage.googleapis.com/v333m-clip/tgt/tour10/th/thmb00${random_int}a.jpg`;

  const loadingMenu = (take) => {
    return (
      <>
        {(state.app.isStoryOwner || state.app.settings.communityCanDeleteTakes) && <MenuItem onClick={(e) => 
        { 
          e.stopPropagation(); 
          if(!isLoggedIn) handleAuth(e, ()=>{ handleTakeDelete(selectedPreview, shot); clearSelectedPreview(); })
          else { handleTakeDelete(selectedPreview, shot); clearSelectedPreview(); } 
          handleTakeAway(e); handleClose() 
        }} sx={{ padding:'.25em .5em', fontSize:'16px', color:theme.palette.secondary.dark, lineHeight:'24px', '&:hover': { background:theme.palette.secondary.light } }}><Delete sx={ iconStyles } /> <Typography variant="span">Delete</Typography>
        </MenuItem>}
      </>
    )
  }
  
  const fullMenu = (take) => {
    return (
        <>
          {state.app.userIsStoryOwner && shot.selected_take_id == take.id && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                selectItemForShot(null);
                handleTakeAway(e);
                handleClose()
              }}
              key={take.id}
              sx={{ padding: ".25em .5em", fontSize: "16px", color: theme.palette.secondary.dark, lineHeight: "24px" }}
            >
              
              <CheckBoxOutlineBlank sx={ iconStyles } />
              <Typography variant="span">Unselect</Typography>
            </MenuItem>
          )}
            {state.app.userIsStoryOwner && shot.selected_take_id != take.id && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                selectItemForShot(take);
                handleTakeAway(e);
                handleClose()
              }}
              key={take.id}
              sx={{ padding: ".25em .5em", fontSize: "16px", color: theme.palette.secondary.dark, lineHeight: "24px", "&:hover": { background: theme.palette.secondary.light } }}
            >
              <CheckBox sx={ iconStyles } />
              <Typography variant="span">Select</Typography>
            </MenuItem>
          )}
            {state.app.userIsStoryOwner && shot.last_take_stream_url && <MenuItem onClick={(e) => { e.stopPropagation(); setTakeMenus([]); setMoveTake(take); setMoveModalOpen(true); e.preventDefault(); handleClose()}} sx={{ padding:'.25em .5em', fontSize:'16px', color:theme.palette.secondary.dark, lineHeight:'24px', position:'relative', '&:hover': { background:theme.palette.secondary.light,  '#moveMenu': { display:'block' } } }}><OpenWith sx={ iconStyles } /> <Typography variant="span">Move</Typography>
          </MenuItem>}
          {(state.app.userIsStoryOwner || state.app.settings.communityCanDownloadTakes) && <MenuItem onClick={(e) => { 
            e.stopPropagation(); 
            if(!isLoggedIn) handleAuth(e, ()=>downloadBlob(take.video_path, getTakeFileName(shot, take)))
            else downloadBlob(take.video_path, getTakeFileName(shot, take));
            handleTakeAway(e); handleClose()
          }} sx={{ padding:'.25em .5em', fontSize:'16px', color:theme.palette.secondary.dark, lineHeight:'24px', '&:hover': { background:theme.palette.secondary.light } }}><Download sx={ iconStyles } /> <Typography variant="span">Download</Typography>
          </MenuItem>}
          {(state.app.userIsStoryOwner || state.app.settings.communityCanVeneer) && take.stream_path != undefined && <MenuItem onClick = {(e) => { e.stopPropagation(); setTakeMenus([]); isLoggedIn ? handleReimagine(e, take) : handleAuth; handleClose() } } sx={{ padding:'.25em .5em', fontSize:'16px', color:theme.palette.secondary.dark, lineHeight:'24px', position:'relative', '&:hover': { background:theme.palette.secondary.light/*, '.wandIcon': { filter:'none' }*/ } }}><MovieFilter sx={iconStyles} /> Reimagine</MenuItem>}
          {(state.app.userIsStoryOwner || state.app.settings.communityCanDeleteTakes) && <MenuItem onClick={(e) => 
          { 
            e.stopPropagation(); 
            if(!isLoggedIn) handleAuth(e, ()=>{ handleTakeDelete(take, shot); clearSelectedPreview(); })
            else { handleTakeDelete(take, shot); clearSelectedPreview(); } 
            handleTakeAway(e); handleClose() 
          }} sx={{ padding:'.25em .5em', fontSize:'16px', color:theme.palette.secondary.dark, lineHeight:'24px', '&:hover': { background:theme.palette.secondary.light } }}><Delete sx={ iconStyles } /> <Typography variant="span">Delete</Typography>
            </MenuItem>}
        </>
    )
  }

  
  if (shots.length) {
    shot = getActiveShot();

    if (shot != null && takes && takesCount == null) {
      setActiveTakes();
    }
  }

    const handleMethodChange = (event) => {
      const methodSelect = event.target.value;
      setSelectedMethod(methodSelect);
      const newModelDefault = methodSelect === "text2vid" ? "text2vid-fluma" : "luma-image";
      setModelDefault(newModelDefault);
      const availableModels = getAvailableModels(methodSelect);
      if (availableModels.some((model) => model.value === newModelDefault)) {
        setChosenModel(newModelDefault); 
      }
    };
  
  const handleModelChange = (event) => {
    setChosenModel(event.target.value);
    if (prompt == "" || prompt == " " || prompt == null) setDefaultPrompt(event);
  };

  const goCancel = () => {
    // history.goBack()
    if(strip) history.push(`/p/${portal_id}/strip`);
    else if (portal_id) history.push(`/p/${portal_id}`);
    else history.push("/storyboards");
  };



  

  const handleTakeAway = (e) => {
    e.stopPropagation();
    let newObj = {};

    for (let el in takeMenus) {
      newObj[takeMenus[el]] = false;
    }

    setTakeMenus((takeMenus) => ({
      ...newObj,
    }));
  };

  const handleTakeMenu = (take) => {
    let newObj = takeMenus;

    if (takeMenus[take.id] != true) {
      for (let id in newObj) {
        newObj[id] = false;
      }
    }
    if (newObj[take.id] === "undefined") newObj[take.id] = true;
    else newObj[take.id] = !newObj[take.id];

    setTakeMenus((takeMenus) => ({
      ...takeMenus,
      ...newObj,
    }));
  };

  

  const handleGo = (newShot) => {
    history.push("/p/" + portal_id + "/" + newShot);
    clearSelectedShotAndItem();
    setSelectedItem(null);
    activeShotId = newShot;

    shot = getActiveShot();
    setActiveTakes();
  };
  const goNextShot = () => {
    let newShot = getShotOrderId(storyPart.order, shot_key + 1);
    handleGo(newShot);
  };
  const goPrevShot = () => {
    let newShot = getShotOrderId(storyPart.order, shot_key - 1);
    handleGo(newShot);
  };
  const goPreview = () => {
    history.push("/p/" + portal_id + "/preview");
  };

  function getTakeFileName(shot, take, ext = "mp4") {
    if (shot && take) {
      const story_title = story.title ? story.title.split(" ").join("_") : "video";
      const shot_title = shot.header.split(" ").join("_");
      const take_id = take.id;
      return ["10z", story_title, shot_title, take_id].join("-") + "." + ext;
    } else {
      return "10z_video" + "." + ext;
    }
  }

  function setActiveTakes(list) {
    let takes_list = list ? list : takes.length ? takes.filter((take) => take.shot_id === activeShotId) : [];
    setTakesList(sortTakes(takes_list));
    setTakesCount(takes_list.length);
    setSelected(takes_list);
  }

  function handleTakeDelete(take, shot) {
    const msg = `would delete ${take.id}`;
    console.log(msg);
    if (confirm("Are you sure you want to delete this take FOREVER?!") == true) {
      const payload = { take_id: take.id, shot_id: take.shot_id, shot_selected_take_id: shot.selected_take_id, shot_last_take_id: shot.last_take_id };
      dispatch({ type: "DELETE_TAKE", payload: payload });
      setDeletingTake(take);
      if (takesList.filter((el) => el.id == take.id)) {
        takesList.splice(
          takesList.findIndex((el) => el.id == take.id),
          1
        );

        setActiveTakes(takesList);
      }
    }
  }

  function moveTakeOpen() {
    /*
    const msg = `would delete ${take.id}`
    console.log(msg)
    console.dir(take);
    if(confirm("Are you sure you want to delete this take FOREVER?!") == true) {
      const payload = {take_id: take.id,
            shot_id: take.shot_id,
            shot_selected_take_id: shot.selected_take_id,
            shot_last_take_id: shot.last_take_id}
      dispatch({ type: "DELETE_TAKE", payload: payload });
      /*console.dir(takesList);
      console.dir(takes_list);
      takes_list = takesList;
      console.dir(takes_list.splice(takes_list.findIndex(el => el.id == take.id), 1));
      console.log('-++++++++++++++++++++')*
      if(takes_list.findIndex(el => el.id == take.id)) {
        takes_list = takes_list.splice(takes_list.findIndex(el => el.id == take.id), 1)
        console.dir(takes_list)
        setTakesList(takes_list)
        console.dir(takesList);
      }
      setTakesCount(takesCount - 1)
      setActiveTakes(takes_list)
    }*/
  }

  function downloadBlob(gs_path, filename) {
    const pathReference = ref(storage, gs_path);

      getDownloadURL(pathReference)
      .then((url) => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
          var blob = xhr.response;
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
          //a.click();
          setTimeout(function () {
            a.remove(a);
            window.URL.revokeObjectURL(url);
          }, 100);
        };
        xhr.open("GET", url);
        xhr.send();
      })
      .catch((error) => {
        console.log("Download FAILED", error.code);
        alert("Download failed,  Reason: " + error.code);

        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  }

  useEffect(() => {
    // Set default based on whether there are takes
    const defaultMethod = takesList?.length > 0 ? 'vid2vid' : 'text2vid';
    const defaultModel = defaultMethod === 'vid2vid' ? 'luma-image' : 'text2vid-fluma';
    
    setReimagineDefaults({
      strength: 3,
      model: defaultModel,
      method: defaultMethod
    })
  }, [takesList])
  
  useEffect(() => {
    if (!chosenModel) {
      setChosenModel(modelDefault);
    }
  }, [modelDefault, chosenModel]); // Depend only on modelDefault, chosenModel

  useEffect(() => {
    models.forEach((value, key) => {
      if (key === chosenModel) {
        setCostPerVeneer(value.cost);
        return;
      }
    });
  }, [chosenModel]);

  useEffect(() => {
    // this code might have been problematic and removed
    //     if(selectedMethod == "vid2vid" && takesList.length == 1) setSelectedReimagineItem(takesList[0]);
    //     else setSelectedReimagineItem(null)
    // Update modelDefault based on initial selectedMethod; avoid resetting chosenModel
    setModelDefault(selectedMethod === "text2vid" ? "text2vid-fluma" : "luma-image");
  }, [selectedMethod]);

  
const getAvailableModels = (method) => {
  if (!method) return [];
  const selectOptions = [];
  Array.from(models.entries()).forEach(([key, value]) => {
    if (value.method === method) {
      selectOptions.push({
        name: value.name,
        value: key,
      });
    }
  });
  return selectOptions;
};

  function createVeneer(prompt, strength, method_name, method, engine, video_path, shot_id, take_id, ancestor_take_id, cost) {
    const storyRef = state.stories.activeStory.ref;
    const seed = -1;
    const random_seed = seed == -1 ? Math.floor(Math.random() * 100000) + 101000000 : 10;
    const veneer_id = nanoid();  // the new take_id is matched for the veneer and the corresponding take
    const ancestor_id = take_id; // this is a hack, not the true anscestor.  Its jusyt the parent for debug. ToDo: Need to pass this in.
    
    const veneer = {
      video_path: video_path,
      audio: "none",
      prompt: prompt,
      strength: strength,
      method_name: method_name,
      method: method,
      engine: engine,
      im_strength: 0.67,
      steps: 36,
      scale: 7,
      seed: random_seed,
      sampler: "ddim",
      skip_frames: 0,
      max_frames: 200,
      shot_id: shot_id,
      take_id: veneer_id,                                      // this is the ID that matches the take that holds veneer, and its used by the backend align to update the take below
      parent_take_id: take_id,                                 // for records, we keep a copy of the source take id
      ancestor_take_id: (ancestor_id)? ancestor_id : take_id,  // oldest known ancestral take_id - the root of a tree
      created_at: serverTimestamp(),
      completed_at: null,
    };
    const veneerData = {
      veneer_id: veneer_id, 
      veneer: veneer, 
      cost: cost, 
      userData: state.userData, 
      story: story
    }

    dispatch({ type:"CREATE_VENEER_FOR_TAKE", payload: veneerData})
  }

  const createVeneerForTake = debounce( () => {
    const modelsToExclude = ['vid2vid', 'comfy-FFAutoCine-v0', 'haiper-image'];
    if (prompt === '' && !modelsToExclude.some(model => chosenModel.includes(model))) {
      alert("Please add prompt to create");
      return
    }

    if(story.isCompetition && story.timerStart != null && (Date.now() - story.timerStart)/1000 > 1800) {
      setShowReimagine(null)
      setCompAlertOpen(true)
      return
    }
    let method_name = "im2im-v4";
    let method = "im2im";
    let engine = "stabilityai/stable-diffusion-2-depth";
    let video_path = null;
    let video_id = null;
    if(selectedReimagineItem) {
      video_path = selectedReimagineItem?.video_path; // the full path w/o the storage bucket
      video_id = selectedReimagineItem?.id;         // for records, we keep a copy of the source video_id as the parent_take_id
    } 
    // compute ancestor_take_id - oldest known ancestral take_id - the root of a tree to the first video
    const this_take =  takesList.filter((take) => take.id == video_id)[0]  
    let ancestor_take_id = ((this_take) && 'ancestor_take_id' in this_take  && (this_take.ancestor_take_id)) ? this_take.ancestor_take_id : video_id 

    /*if (chosenModel !== null) {
      method_name = chosenModel.split(',')[0];
      method = chosenModel.split(',')[1];
      engine = chosenModel.split(',')[2];
    }*/
    models.forEach((value, key) => {
      if (key == chosenModel) {
        method_name = key;
        method = value.method;
        engine = value.engine;
        return;
      }
    });
    if (method == "text2vid") {
      // anything that starts w text2video has an API shortcut, where is needs to pass upa video, but does not use it
      // TO DO: Remove defaultPath and replace with null ... which should work if we depricate zeroscope
      let defaultPath =
        hostConfig.env == "prod"
          ? "https://storage.googleapis.com/v0zebra.appspot.com/p/st/PsTKsKgJLNGsMiSDiAao/sh/qTF8fXtuzjPuQjj4hJ0aO/ta/cfc95bce-c1a4-43ec-9362-96944a9ebab8/q/2023-07-19T18_27_15_467Z-1.mp4"
          : "https://storage.googleapis.com/v0zebra-dev.appspot.com/p/st/kJW-WfbW-yS9pTY0sxJd/sh/94a49eff-460a-4ebf-af29-9aa7ffd04086/ta/7ab97cde-0230-4730-b5e6-17e0436cf4e7/q/2023-09-28T06-55-41-041Z-1.mp4";
      video_path = defaultPath;
      video_id = "";
    }
    createVeneer(prompt, strength, method_name, method, engine, video_path, shot_id, video_id, ancestor_take_id, cost_per_veneer); // cost is fixed at 10.  fps not under control. selectedItem.dur * fps * lookup
    dispatch({ type: "UPDATE_STORY_SHOT_PROMPT", payload: { prompt: prompt, strength: strength, shot_id: shot_id, model: chosenModel } });

    if (method == "im2im") {
      alert("Creation started. Your new video will be added in 15-30 mins.");
    } else {
      toast.success(Messages.info.videoGenerationStarted);
    }

    setSelectedReimagineItem(null);
    setShowReimagine(null);
  }, 300)

  /*function fileUpload(e) {
    e.stopPropagation();
    e.preventDefault();
    uploadFile.current.value = ""
    uploadFile.current.click()
  }
  
  function fileCapture(e) {
    e.stopPropagation();
    e.preventDefault();
    captureFile.current.value = ""
    captureFile.current.click()
  }  


  async function onFileUploadChange(shot_id, event) {
    if(story.isCompetition && story.timerStart != null && (Date.now() - story.timerStart)/1000 > 1800) {
      setCompAlertOpen(true)
      return
    }
    for (var i = 0; i < event.target.files.length; i++ ) {
      const file = event.target.files[i];
      if (file) {
        const file_MB = file.size / 1000000
        const alert_thresh_MB = 25
        const max_MB = (state.app.userIsStoryOwner && state.app.settings.userCanUploadLargeFiles) ? 250 : 50
        if (file_MB > max_MB) {
          alert(`Please use shorter video clips. ${file_MB.toPrecision(3)}MB is over the ${max_MB}MB limit for this project's settings.`)
        } else {
          if(story.isCompetition && (story.timerStart == undefined || story.activeStory.timerStart == null)) {
            const timerStart = Date.now()
            const payload = {'timerStart': timerStart}
            dispatch({ type: "START_STORY_TIMER", payload: payload });
          }
          if (file_MB > alert_thresh_MB) {
            alert(`This file is larger than expected for the recommended 1-6 second video. Upload will resume.`)
          }

          console.log(file)
          console.log("onFileUploadChange ... " + shot_id + " " + file_MB.toPrecision(3) + "MB")
  
          const take_id = uuidv4();
          const storageDir = "media"
          //const path = `${storageDir}/${filename}.mp4`
  
          let oid = "unknown"
          let sid = "unknown"
          if (state.stories.activeStory && state.stories.activeStory.ref) {
            // const story_path = state.stories.activeStory.ref.u_.path 
            // const OID_ind = story_path.segments.findIndex((item) => item == "users") + 1 
  
            const story_path_array = state.stories.activeStory.ref.path.split("/");
  
            //console.log("story_path", story_path_array)
            const OID_ind = story_path_array.findIndex((item) => item == "users") + 1 
            //console.log("OID_ind", OID_in dd)
            oid = story_path_array[OID_ind]
            //console.log("OID", oid)
  
            const SID_ind = story_path_array.findIndex((item) => item == "stories") + 1 
            //console.log("SID_ind", SID_ind)
            sid = story_path_array[SID_ind]
            //console.log("SID", sid)
            //alert(SID_ind)
            //alert(OID_ind)
          }
  
          const uploader_id = currentUser ? currentUser.uid : "unknown"
          const path = `${storageDir}/us/${oid}/st/${sid}/up/${uploader_id}/ta/${uuidv4()}.mp4`
          const uploadPromise = uploadBlob(file, dispatch, path)
          let result = await uploadPromise;

          if (result ==='upload completed') {
            const payload = {video_path: path, 
              thumbnail:  assets['processing_thumb'],
              shot_id: shot_id,
              take_id: take_id,
            }
            event.target.value = null
            dispatch({ type: "ADD_TAKE_TO_SHOT", payload: payload })
            //setSelected();
            setTakesCount(takesCount + 1)
            const lastPayload = {
              take_id: take_id, 
              image: assets['processing_thumb'],
              stream_path: path, 
              shot_id: shot_id
            }; 
            dispatch({ type: "UPDATE_SHOT_LAST_TAKE", payload: lastPayload })
         }
        }
      } else {
          console.log("onFileUploadChange ... empty file ... canceled event") 
      }
    }
  }*/

  function copyPrompt(item, type) {
      navigator.clipboard.writeText(veneerLUT[item.id].prompt);
      setShowSuccess(true);
    const timer = setTimeout(() => {
      setShowSuccess(false);
      //setShowSelectedSuccess(false);
    }, 1500);
  }

  const stopPlaying = (id) => {
    setPlayTake({ ...playTake, [id]: false });
  };

  const playerInit = (take) => {
    if (!showVid[take.id]) setShowVid({ ...showVid, [take.id]: true });
    setPlayTake({ ...playTake, [take.id]: true });
    setVidHover({ ...vidHover, [take.id]: true });
  };

  const handlePlayer = (take) => {
    if (take.stream_path) {
      playerInit(take);
    }
  };

  const handlePlay = (id) => {
    if (!vidPlay[id]) setVidPlay({ ...vidPlay, [id]: "video-play" });
  };

  if (Object.keys(showVid).length === 0 && takesList.length) {
    let showObj = {};
    let takeObj = {};
    let playObj = {};
    for (let take of takesList) {
      showObj[take.id] = false;
      takeObj[take.id] = false;
      playObj[take.id] = false;
    }
    setShowVid(showObj);
    setPlayTake(takeObj);
    setVidPlay(playObj);
  }

  useEffect(() => {
    setShowVid({});
    setPlayTake({});
    setVidPlay({});
    setVidHover({});
  }, [location]);

    
  
  /*useEffect(() => {
    if (preview_volume !== null) {
      dispatch({ type: "TOGGLE_VOLUME"})
      setPreviewVolume(preview_volume);
    }
  }, [preview_volume]);*/

  async function selectItemForShot(selectedItem) {
    let selectedShot = shot;
    let shot_selected_take_payload;

    if (selectedItem != null && isItemFromActiveShot(selectedItem)) {
      const take_id = selectedItem.id;
      shot_selected_take_payload = { shot_id: selectedShot.id, take_id: take_id, image: selectedItem.thumbnail, stream_path: selectedItem.stream_path };
      setSelectedItem(selectedItem);
    } else if (selectedItem == null && shot.selected_take_id) {
      shot_selected_take_payload = { shot_id: selectedShot.id, take_id: shot.selected_take_id, image: null, stream_path: null };
      clearSelectedShotAndItem();
    }

    dispatch({ type: "UPDATE_SHOT_SELECTED_TAKE", payload: shot_selected_take_payload });
  }

  if (state.app.isMobile && playMain == false) setPlayMain(true);
  //selected_take = <Box><ZebraPlayer video_urls={[]} /></Box>
  if (selectedItem && isItemFromActiveShot(selectedItem) && selectedItem.stream_path) {
    selected_take = (
      <Box>
        <Typography variant="h2">{shot?.selected_take_id ? "Selected" : "Latest"}</Typography>
        {/*!selectedItem &&  <img src={selectedItem.thumbnail} fluid="true" />}
        {selectedItem && selectedItem.thumbnail && <img src={selectedItem.thumbnail} fluid="true"  />*/}
        {selectedItem && selectedItem.thumbnail && (
          <Link
            href="#"
            onMouseOver={() => {
              setPlayMain(true);
              setMainHover(true);
            }}
            onMouseOut={() => setMainHover(false)}
            onClick={(e) => {
              e.stopPropagation();
              handlePreview(selectedItem);
            }}
            sx={{ display: "block", position: "relative" }}
          >
            <ZebraPlayer
              sx={{ width: "100%", height: "100%" }}
              video_urls={[selectedItem.stream_path]}
              controls={false}
              playing={playMain}
              loop={mainHover}
              onEnded={() => {
                setPlayMain(false);
              }}
            />
            <FitScreen
              sx={{
                position: "absolute",
                display: "block",
                bottom: "30px",
                right: "30px",
                color: theme.palette.primary.contrastText,
                [theme.breakpoints.down("md")]: { bottom: "10px", right: "10px" },
              }}
            />
          </Link>
        )}
        {(state.app.userIsStoryOwner || (!state.app.userIsStoryOwner && state.app.settings.promptsVisible)) && selectedItem && veneerLUT[selectedItem.id] && veneerLUT[selectedItem.id].prompt && (
          <Box sx={{ position: "relative", marginBottom: "10px" }}>
            <Box sx={{ paddingTop: "15px", display: "flex" }}>
              <Typography sx={{ fontWeight: "bold", paddingRight: "5px" }}>Prompt</Typography>
              <ContentCopy
                className="icon"
                onClick={() => {
                  copyPrompt(selectedItem, "selected");
                }}
              />
              <Typography sx={{ fontStyle: "italic" }}>{veneerLUT[selectedItem.id].prompt}</Typography>
            </Box>
            {showSuccess && (
              <Box className="copy-success" sx={successSelectedStyles}>
                Prompt Copied!
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  } else if (takesList && takesList.length && takesList[0].stream_path) {
    selected_take = (
      <Box>
        <Typography variant="h2">Latest</Typography>
        {/*!selectedItem &&  <img src={selectedItem.thumbnail} fluid="true" />}
      {selectedItem && selectedItem.thumbnail && <img src={selectedItem.thumbnail} fluid="true"  />*}
      <Box sx={{ padding: "4em", mb: "4em", borderWidth:"1px", borderStyle: "solid", borderColor: theme.palette.primary.outline }}>
        <Box sx={{ height: "350px", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center", [theme.breakpoints.down('md')]: { height:"150px"} }}>Your selected take will display here</Box>
      </Box>*/}
        <Box
          onMouseOver={() => {
            setPlayMain(true);
            setMainHover(true);
          }}
          onMouseOut={() => setMainHover(false)}
          onClick={(e) => {
            e.stopPropagation();
            handlePreview(takesList[0]);
          }}
          sx={{ display: "block", position: "relative" }}
        >
          <ZebraPlayer
            sx={{ width: "100%", height: "100%" }}
            video_urls={[takesList[0].stream_path]}
            controls={false}
            playing={playMain}
            loop={mainHover}
            onEnded={() => {
              setPlayMain(false);
            }}
          />
          <FitScreen
            sx={{ position: "absolute", display: "block", bottom: "30px", right: "30px", color: theme.palette.primary.contrastText, [theme.breakpoints.down("md")]: { bottom: "10px", right: "10px" } }}
          />
        </Box>
        {(state.app.userIsStoryOwner || (!state.app.userIsStoryOwner && state.app.settings.promptsVisible)) && takesList.length && veneerLUT[takesList[0].id] && veneerLUT[takesList[0].id].prompt && (
          <Box sx={{ position: "relative", marginBottom: "10px" }}>
            <Box sx={{ paddingTop: "15px", display: "flex" }}>
              <Typography sx={{ fontWeight: "bold", paddingRight: "5px" }}>Prompt</Typography>
              <ContentCopy
                className="icon"
                onClick={() => {
                  copyPrompt(takesList[0], 'first');
                }}
              />
              <Typography sx={{ fontStyle: "italic" }}>{veneerLUT[takesList[0].id].prompt}</Typography>
            </Box>
            {showSuccess && (
              <Box className="copy-success" sx={successSelectedStyles}>
                Prompt Copied!
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  }

  const tabChange = function (e, value) {
    setTabValue(value);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function getShotOrderId(order, key) {
    if (shots.length) {
      let shot = shots.filter((shot) => shot.id == order[key]);
      if (shot.length) {
        shot = shot[0];
        return shot.id;
      }
    }
  }

  function getActiveShot() {
    if (shots.length) {
      let shot = shots.filter((shot) => shot.id === activeShotId);
      if (shot.length) {
        shot = shot[0];

        return shot;
      }
    }
  }
  function setPrevNext() {
    // set prev / next
    if (story.parts) {
      story.parts.map((part, key) => {
        storyPart = part;
        shot_key = Object.keys(part.order).find((key) => part.order[key] === activeShotId) * 1;
        prev = { click: goPrevShot, type: "secondary", text: "Previous Shot", disabled: !(shot_key && shot_key != 0) };
        next = shot_key == shots.length - 1 ? { click: goPreview, type: "primary", text: "Preview" } : { click: goNextShot, type: "primary", text: "Next Shot", disabled: !(shot_key != null) };
      });
    }
  }

  function handlePreview(take) {
    const takeIndex = takesList && take && takesList.findIndex(item => item.id === take.id);
    setActiveShotIndex(takeIndex);
    setSelectedPreview(take);
  }
  
  function handleReimagine(e, take) {
    if (e) e.preventDefault();
  
    // First set the method and model based on whether we have a take
    if (take) {
      setSelectedMethod('vid2vid');
      setChosenModel('luma-image');
    }
    // Then handle the take selection and other settings
    if (take && veneerLUT[take.id]) {
      // Use existing veneer data if available
      const defaults = {
        seed: veneerLUT[take.id].seed,
        engine: veneerLUT[take.id].engine,
        prompt: veneerLUT[take.id].prompt,
        method: veneerLUT[take.id].method,
        model: veneerLUT[take.id].method_name,
        strength: veneerLUT[take.id].strength,
        source: veneerLUT[take.id].video_path,
      };
  
      setPrompt(defaults.prompt);
      setStrength(defaults.strength);
      setSelectedMethod(defaults.method);
      setChosenModel(defaults.model); // Set model from veneer data
    } else {
      // No veneer data; use defaults or preserve user selections
      setDefaultPrompt(e);
      setStrength(reimagineDefaults.strength);
    }
  
    // Set the selected take after method/model are set
    setSelectedMethod(reimagineDefaults.method);
    setChosenModel(reimagineDefaults.model);
    setSelectedReimagineItem(take || null);
    setSelectedPreview(null);
    setShowReimagine(true);
  }

  function handleAuth(e, authFunc) {
    e.preventDefault();
    setRedirectLoc(location.pathname)
    setAuthFunc(()=>authFunc);
    
    setShowAuth(true);
  }

  const handleShotLastTake = () => {
    const takes_list = takesList.filter((take) => take.id != moveTake.id)
    const lastTake = takes_list[0];
    const prev_shot_payload = {
      shot_id: shot.id,
      take_id: lastTake.id,
      image: lastTake.thumbnail,
      stream_path: lastTake.stream_path,
    };
    dispatch({ type: "UPDATE_SHOT_LAST_TAKE", payload: prev_shot_payload });
  }

  const moveTakeAdd = async () => {
    const random_int = Math.floor(Math.random() * 18);
    const biased_int = [0, 0, 1, 1, 1, 1, 2, 2, 3, 3, 5, 5, 6, 6, 7, 7, 8, 8, 0][random_int];
    const shot_type_filenames = ["wide.png", "mid.png", "action.png", "close.png", "empty.png", "xcu.png", "two_shot.png", "ots.png", "full.png", "action_close.png"];
    const shot_type = shot_type_filenames[biased_int];
    const random_image = `https://storage.googleapis.com/v0zebra.appspot.com/resource/shot_type/${shot_type}`;
    const matchIndex = /\([a-z]\)$/g.exec(shot.header) ? /\([a-z]\)$/g.exec(shot.header).index : -1;
    const letter = matchIndex != -1 ? String.fromCharCode(shot.header.charCodeAt(matchIndex + 1) + 1).toLowerCase() : "";

    const new_panel_desc = ["Wide shot", "Mid shot", "Action shot", "Close up of face", "", "Extreme close up", "Two-Shot", "Over the shoulder", "Full shot", "Action Close up"][biased_int];
    const title = matchIndex != -1 ? shot.header.replace(shot.header.match(/\([a-z]\)/), "(" + letter + ")") : shot.header + " (b)";
    const description = shot.description;
    let storyPart = null;
    let shot_key = 1;
    let newTakes = takes;
    story.parts.map((part, key) => {
      let found = Object.keys(part.order).find((key) => part.order[key] === activeShotId) * 1;
      if (typeof found == "number") {
        storyPart = story.parts[key];
        shot_key = found;
      }
      //shot_key = Object.keys(part.order).find(key => part.order[key] === shot.id)*1
    });
    
    const newShot = {
      id: nanoid(),
      header: title,
      description: description,
      image: random_image,
      meta: "",
      prompt: "",
      take_id: null,
      selected_take_id: null,
      image: random_image,
      stream_path: null,
      deleted_at: null,
    };
    if (storyPart) {
      const payload = {
        part_id: storyPart.id,
        index: shot_key + 1,
        shot: newShot,
        shot_id: newShot.id
      };
      dispatch({ type: "ADD_STORY_SHOT", payload: payload });

      const shot_selected_take_payload = {
        shot_id: newShot.id,
        take_id: moveTake.id,
        image: moveTake.thumbnail,
        stream_path: moveTake.stream_path,
      };

      const selectedPayload = {
        shot_id: shot.id,
        dest_shot_id: newShot.id,
        take_id: moveTake.id,
        prev_last_take_id: shot.last_take_id,
        prev_selected_take_id: shot.selected_take_id,
        image: moveTake.thumbnail,
        stream_path: moveTake.stream_path,
      };

      dispatch({ type: "MOVE_TAKE_TO_SHOT", payload: selectedPayload });
      dispatch({ type: "UPDATE_SHOT_LAST_TAKE", payload: shot_selected_take_payload });
      if(takesList.length && takesList[0].id == moveTake.id) handleShotLastTake();

      //history.push('/p/' + portal_id + '/' + newShot.id)
      //setSelected()

      setMoveModalOpen(null);
      //await setActiveTakes()
      setMoveTakeShot(newShot.id);
    } else {
      alert("There was an error moving your take. Please try again.");
      setMoveModalOpen(false);
    }
  };

  const moveTakeExisting = async () => {
    let newShot = shots.filter((shot) => shot.id == moveToShot)[0];
    //let takes_list = JSON.parse(JSON.stringify(state.stories.activeTakeList));
    let newTake, allTakes, index;
    //const takePayload = {...moveTake, ...{shot_id: moveShot.id} }
    const selectedPayload = {
      shot_id: shot.id,
      dest_shot_id: newShot.id,
      take_id: moveTake.id,
      take_modified: Date.now(),
      prev_last_take_id: shot.last_take_id,
      prev_selected_take_id: shot.selected_take_id,
      image: moveTake.thumbnail,
      stream_path: moveTake.stream_path,
    };
    dispatch({ type: "MOVE_TAKE_TO_SHOT", payload: selectedPayload });
    //const selectedPayload = {...moveTake, ...{shot_id: moveShot.id} }
    const shot_selected_payload = {
      shot_id: newShot.id,
      take_id: moveTake.id,
      image: moveTake.thumbnail,
      stream_path: moveTake.stream_path,
    };
    dispatch({ type: "UPDATE_SHOT_LAST_TAKE", payload: shot_selected_payload });
    if(takesList.length && takesList[0].id == moveTake.id) handleShotLastTake();
    //dispatch({ type: "UPDATE_ACTIVE_TAKE_LIST", payload: state.stories.activeTakeList });
    //history.push('/p/' + portal_id + '/' + moveShot.id)
    //setSelected()
    setMoveModalOpen(null);
    //await setActiveTakes(takes_list.filter(take => take.shot_id == newShot.id))
    setMoveTakeShot(newShot.id);
  }

  const comp_alert_modal = (
    <Dialog
      id="comp-modal"
      onClose={() => {
        clearCompAlertModal();
      }}
      /*onOpen={() => setPreviewOpen(true)}*/
      open={compAlertOpen != null}
      fullWidth={true}
      maxWidth="sm"
      sx={{ zIndex: "9999" }}
    >
      <DialogTitle sx={{ padding: "1em 1.5em 0", [theme.breakpoints.down("md")]: { padding: "1em 1em 0" } }}>
        The competition is over
        <Box  onClick={clearCompAlertModal} sx={{ float: "right", "&:hover": { cursor: "pointer" } }}>
          <Close sx={{ color: theme.palette.primary.light }} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: "0 2em 0", maxHeight: "70vh", [theme.breakpoints.down("md")]: { padding: "0 1em 0" } }}>
        <Box>Uploads / Generations are no longer allowed.</Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          padding: "1em 2em",
          [theme.breakpoints.down("md")]: { marginBottom: "1rem !important" },
          [theme.breakpoints.down("sm")]: { padding: "0 1em 1em", flexDirection: "column-reverse" },
        }}
      >
        <Button
          onClick={() => {
            clearCompAlertModal();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  const move_modal = (
    <Dialog
      id="move-takes-modal"
      onClose={() => {
        clearMoveModal();
      }}
      /*onOpen={() => setPreviewOpen(true)}*/
      open={moveModalOpen != null}
      fullWidth={true}
      maxWidth="md"
      sx={{ zIndex: "9999" }}
      scroll="paper"
    >
      <DialogTitle sx={{ padding: "1em 1.5em 0", [theme.breakpoints.down("md")]: { padding: "1em 1em 0" } }}>
        Move your take
        <Link href="#" onClick={clearMoveModal} sx={{ float: "right", "&:hover": { cursor: "pointer" } }}>
          <Close sx={{ color: theme.palette.primary.light }} />
        </Link>
      </DialogTitle>
      <DialogContent sx={{
        padding: "0 2em 0", [theme.breakpoints.down("md")]: { padding: "0 1em 0" },
        overflowY: "auto",
        maxHeight: "calc(100vh - 150px)",
      }}>
        <TabContext value={tabValue} aria-label="basic tabs">
          <TabList onChange={tabChange}>
            <Tab label="New Shot" value="new" />
            <Tab label="Existing Shot" value="existing" />
          </TabList>
          <TabPanel value="new">
            <Box sx={{ marginBottom: "25px" }}>Create a new shot and move the take to it.</Box>
          </TabPanel>
          <TabPanel value="existing">
            <Box>
              <Box sx={{ marginBottom: "25px" }}>Choose an existing shot and move the take to it.</Box>
              {"parts" in story &&
                story.parts.map((part, key) => (
                  <ShotGroup
                    key={key}
                    title={null}
                    displayImages={true}
                    viewLatestTake={true}
                    part_id={part.id}
                    //setSelectedShotAndAction={setSelectedShotAndAction}
                    takes={null}
                    shots={shots}
                    minimal={true}
                    setMoveToShot={setMoveToShot}
                  />
                ))}
            </Box>
          </TabPanel>
        </TabContext>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          padding: "1em 2em",
          [theme.breakpoints.down("md")]: { marginBottom: "1rem !important" },
          [theme.breakpoints.down("sm")]: { padding: "0 1em 1em", flexDirection: "column-reverse" },
        }}
      >
        {tabValue == "new" && (
          <Button
            onClick={() => {
              moveTakeAdd();
            }}
          >
            Add Shot and Move
          </Button>
        )}
        {tabValue == "existing" && (
          <Button
            onClick={() => {
              moveTakeExisting();
            }}
          >
            Move Take
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  const getLatestVeneerPrompt = () => {
    for (var prop in veneerLUT) {
      if (veneerLUT[prop].prompt != "" && veneerLUT[prop].prompt != " ") return veneerLUT[prop].prompt;
    }
    return null;
  };

  const clearPrompt = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setPrompt("");
    setIsPromptCleared(true)
  }

  function setDefaultPrompt(e) {
    if(isPromptCleared) {
      setPrompt("")
    } else {
      const latestPrompt = (veneerLUT) ? getLatestVeneerPrompt() : null
      if(shot != null && (typeof shot.prompt !== 'undefined') && shot.prompt.length > 0) setPrompt(shot.prompt)
      else if(latestPrompt) setPrompt(latestPrompt)
      else if(prompt == '' || prompt == ' ') setPrompt(defaultPrompt)
    }
  }

  if (shots.length > 0) {
    if (activeShotId && shot == null) {
      //shot = shots.filter((shot, key) => isActiveShot)[0]
      // for(var key of shots.keys()) {
      //   console.log(key)
      //   console.log(activeShotId)
      //   console.log(shots[key].id)
      //   if(shots[key].id == activeShotId) {
      //     shot_key = key
      //     shot = shots[key]
      //   }
      // }
      /*shot = shots.map((shot, key) => {
        console.log(key)
        if(shots[key].id == activeShotId) {
          shot_key = key
          console.dir(shot)
          return shot
        }
      })*/
      /*shot = Object.keys(shots)
        .filter(shot => shot.id == activeShotId)
        .reduce((obj, key) => {
          obj[key] = shots[key];
          alert(key)
          shot_key = key
          return obj;
        }, [])[0];*/
      //shot = shot[0]
      //shot = shots[shot_key-1]
      // if(shot) {
      //   //setSelectedShotAndAction(shot, 'select_take')
      // } else {
      //   return false
      // }
    }

    //shot = getActiveShot() //shots.filter(shot => shot.id===shotID)[0]

    if (shot != null) {
      /*for(var key of shots.keys()) {
          console.log(key)
          console.log(activeShotId)
          console.log(shots[key].id)
          if(shots[key].id == activeShotId) {
            
            shot_key = key
            shot = shots[key]
          }
        }*/
      //setSelectedShotAndAction(shot, 'select_take')
      if (prompt == null) {
        setDefaultPrompt();
      }
      if (takesList && takesList.length) {
        takes_group = (
          <Grid container spacing={2}>
            <DropUpload maxFiles={10} type="add" />
            {takesList.map((take) => (
            <DropUpload key={take.id} maxFiles={10} type="wrap">
              <Grid key={take.id} xs={6} sm={4} md={3} lg={2} sx={{ justifyContent: "space-around" }}>
                    <Item sx={{ border: shot && shot.selected_take_id == take.id ? "3px solid " + theme.palette.primary.dark : "", position: "relative", zIndex: takeMenus[take.id] ? "9999" : "1" }}>
                    <Box
                      style={{cursor:'pointer'}}
                        className={`video-wrapper ${vidPlay[take.id]}`}
                        onMouseOver={() => {
                          handlePlayer(take);
                        }}
                        onMouseOut={() => {
                          setVidHover({ ...vidHover, [take.id]: false });
                        }}
                        sx={{ display: "block" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if(take.stream_path != undefined) handlePreview(take);
                          else alert("Please wait until video is done processing.");
                        }}
                      >
                        <Box component="img" src={take.thumbnail} sx={{ width: "100%", height: "auto", display: "block" }} />
                        {showVid[take.id] && (
                          <Box className={"video"}>
                            <ZebraPlayer
                              sx={{ width: "100%", height: "100%" }}
                              video_urls={[take.stream_path]}
                              controls={false}
                              playing={playTake[take.id]}
                              onPlay={() => {
                                handlePlay(take.id);
                              }}
                              onEnded={() => {
                                stopPlaying(take.id);
                              }}
                              loop={vidHover[take.id]}
                            />
                          </Box>
                        )}
                      </Box>
                        <Box sx={{ position: "relative", zIndex: "99", marginTop: ".5em", padding: "0 1em" ,
    display: "flex",
    justifyContent: "center", 
    alignItems: "center",      
             
  }}>
                          <Button 
                          variant="text"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e)=>{
          handleClick(e, take)
          // handleTakeMenu(take)
        }}
        sx={{
    display: "flex",                 
    alignItems: "center",            
    justifyContent: "center",        
    width: "40px",                  
    height: "40px",                  
    color: "#272727",
    position: "relative",
    zIndex: "1",
    backgroundColor: "transparent",
    boxShadow: "none",              
    border: "none",                 
    padding: "0",                   
    minWidth: "auto",               
    "&:hover": {
      backgroundColor: "transparent", 
    }
  }}>
        <MoreHoriz />
      </Button>     
      <Menu
          id="basic-menu"
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          disableScrollLock
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            boxShadow: "none", 
            "& .MuiPaper-root": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.02)"
        }}}>
            <Box>{takeMenu}</Box>
        </Menu>
                  </Box>
              </Item>
            </Grid>
          </DropUpload>
      ))}
      </Grid>
        );
      } else {
        takes_group = (
          <Grid container>
            <DropUpload maxFiles={10} type="add" status={ takesList.length ? "" : "empty" } />
          </Grid>
        );
      }
    }
  }

  function capIt(str) {
    let newStr;
    str = str.toLowerCase();
    newStr = str.charAt(0).toUpperCase() + str.slice(1);

    return newStr;
  }

  function handlePromptFormChange(e) {
    const current_prompt = e.target.value;
    const max_length = 350; // 210

    if (current_prompt.length < max_length) {
      setPromptValid(true);
    } else {
      setPromptValid(false)
    }
    if (e && current_prompt === '') {
      setIsPromptCleared(true)
    }
    setPrompt(current_prompt);
  }

  function handleStrengthChange(e) {
    setStrength(e.target.value);
  }
  const loginDialog = (
    <Dialog id="reimagine_viewer" onClose={clearReimagine} open={showAuth}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid " + theme.palette.primary.light, marginBottom: "1em" }}>
        <Typography variant="h2">Login</Typography>
        <Link href="#" onClick={clearAuth} sx={{ "&:hover": { cursor: "pointer" } }}>
          <Close />
        </Link>
      </DialogTitle>
      <DialogContent>
        <AuthBox version="login-modal" redirect={redirectLoc} authFunc={authFunc} value_prop="Log in to contribute to this project." />
      </DialogContent>
    </Dialog>
  );

  const reimagine_cta =
    state.app.userIsStoryOwner || state.app.settings.communityCanVeneer ? (
      <Box id="reimagine_cont">
        <Typography variant="p" sx={orStyles}>
          -- or --
        </Typography>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          Create an AI video with a prompt or reimagine an existing take
        </Typography>
        <Button variant="contained" sx={{ width: "fit-content", textAlign: "center", display: "block", margin: "0 auto" }} onClick={ (e) => {isLoggedIn ? handleReimagine(e) : handleAuth(e, ()=>handleReimagine(e))}}>
          <MovieFilter sx={{ width: "auto", height: "25px", marginLeft: "5px", verticalAlign: "middle" }} /> Generate
        </Button>
      </Box>
    ) : null;
  const vidOption = (takesList.length > 0) ? { value: "vid2vid", name: "Video to Video" } : null;

  const reimagine_modal = (
    <Dialog id="reimagine_viewer" disableRestoreFocus onClose={clearReimagine} open={showReimagine}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid " + theme.palette.primary.light, marginBottom: "1em" }}>
        <Typography variant="h2">{takesList ? "Reimagine" : "Create an AI Video"}</Typography>
        <Box onClick={clearReimagine} sx={{ "&:hover": { cursor: "pointer" } }}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ marginBottom: "25px" }}>
          <Typography variant="body2">
            {takesList ? "Create a prompt, choose your model, and select a take below to reimagine your video with AI" : "Enter a prompt below to create an AI video"}
          </Typography>
        </Box>
        <Box component="section" sx={sectionStyles}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="form-input-prompt-label" sx={{ '&.MuiInputLabel-shrink': { padding:'0 5px', background:'white' } }}>Prompt</InputLabel>
            <Box sx={{ display: "flex" }}>
            <OutlinedInput
                labelId='form-input-prompt-label'
                id='form-input-prompt'
                control={<Input  />}
                value={prompt}
                variant="outlined"
                onChange={(e) => handlePromptFormChange(e)}
                error={!promptValid}
                multiline
                rows={3}
                sx={{width: 'calc(100% - 38px)' }}
              />
              {prompt && (
                  <Link          
                    sx={{ ...clearStyles, ...(prompt ? { opacity: 1, visibility: 'visible' } : { opacity: 0, visibility: 'hidden' }) }}
                  onClick={(e) => clearPrompt(e)}>x</Link>
                )}
            </Box>
            {!promptValid && (
              <FormHelperText error id="form-input-prompt-error">
                Prompt is too long.
              </FormHelperText>
            )}
              </FormControl>
              </Box>
              {models.has(chosenModel) && models.get(chosenModel).method == 'vid2vid' ?
                <Box sx={sliderStyles} className={showSlider ? 'open' : 'close'}>
                  <InputLabel id="form-prompt-strength-label" onClick={(e) => { setShowSlider(!showSlider) } }>Prompt Influence: {strengthMap[strength-1]} <ArrowDropDown className="arrow-drop" /></InputLabel>
                  <Box className="slider-wrap">
                    <Slider
                      aria-label="Prompt Influence"
                      id="prompt-strength"
                      labelId='form-prompt-strength-label'
                      defaultValue={strength}
                      value={strength}
                      step={1}
                      onChange={(e) => handleStrengthChange(e)}
                      valueLabelDisplay="off"
                      min={1}
                      max={5}
                      marks={[
                        {
                          value:1, 
                          label:'Subtle'
                        }, 
                        {
                          value:3, 
                          label:'Normal'
                        }, 
                        { 
                          value:5, 
                          label:'Strong'
                        }
                      ]}
                    />
                  </Box>
                </Box>
              : null }
              {takesList && 
                <Box component="section" sx={{...sectionStyles, }}>
                  <Grid container spacing={1.5}>
                    <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                      <GenericSelect
                        label="Select Method"
                        value={selectedMethod}
                        onChange={handleMethodChange}
                        formControlWidth="100%"
                        options={[
                          { value: "text2vid", name: "Text to Video" },
                          vidOption,
                        ]}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                      <GenericSelect
                        label="Choose your Model"
                        value={chosenModel}
                        onChange={handleModelChange}
                        formControlWidth="100%"
                        options={getAvailableModels(selectedMethod)}
                        //disabledCondition={(item) => takesList.length === 0 && item.value.method.indexOf("vid") !== -1}
                      />
                    </Grid>
                  </Grid>
                  
                </Box>
              }
              {((models.has(chosenModel) && models.get(chosenModel).method != "text2vid") && takesList) &&
                <Box component="section" sx={sectionStyles}>
                  <InputLabel>Select a Take</InputLabel>
                  <Grid container spacing={2}>
                    {takesList.map((item, key) => ( 
                      <Grid xs={4} md={2} key={key}>
                        <Item>
                        <Box onClick={() => setSelectedReimagineItem(item)} className={`video-wrapper ${vidPlay[item.id]}`} onMouseOver={ () => { handlePlayer(item) } }> 
                          {/*item.stream_path && selectedReimagineItem && item.id==selectedReimagineItem.id && <Link href="#" onClick={() => selectItemForReimagine(item)} ><ZebraPlayer video_urls={[item.stream_path]} light={false} controls={false}/></Link>*/}
                          {!(item.stream_path && selectedReimagineItem && item.id==selectedReimagineItem.id) && <Box component="img" src={item.thumbnail} sx={{ width:'100%', height:'auto', display:'block' }} />}
                          { showVid[item.id] && <Box className={'video'}><ZebraPlayer sx={{ width:'100%', height:'100%' }} video_urls={[item.stream_path]} controls={false} playing={playTake[item.id]} onPlay={ () => { handlePlay(item.id) } } onEnded={ ()=> { stopPlaying(item.id) } } loop={false} /></Box> }
                          {selectedReimagineItem && item.id==selectedReimagineItem.id && "✓"}
                        </Box>
                        </Item>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              }
        <Box component="section" sx={sectionStyles}>
          <Box component="p" sx={{ fontStyle: "italic" }}>
            Cost to run: {cost_per_veneer};{isLoggedIn && <> Remaining Credits: {state.userData.credits} </>}
            {Object.keys(state.userData).length === 0 && (
              <>
                {" "}
                <br />
                <em>
                  You must <Link href="/new?utm_source=app&utm_medium=organic&utm_campaign=get_credits_internal">log in</Link> for initial credits to generate media.
                </em>
              </>
            )}
          </Box>
          {Object.keys(state.userData).length && state.userData.credits <= 200 && 
            <Box component="div" sx={{ background: "#272727", display: "inline-block", color: "white", padding: "5px 10px" }}>
              Credits Running Low! <Link href="/account/credits" onClick={ () => { preventDefault(); history.push('/account/credits'); }} sx={{ color: "white", textDecoration: "underline", "&:hover": { cursor: "pointer", textDecoration: "none" } }}>Purchase more now &rsaquo;</Link>
            </Box>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={clearReimagine} variant="outlined">
          Cancel
        </Button>
        {/*selectedItem && activeEditState === 'Select' &&
                  <Button
                    content="Select"
                    labelPosition='right'
                    icon='checkmark'
                    //onClick={applySelectTakeForShot}
                    positive
                  />*/}
        {
          /*&& activeEditState === 'Recreate' &&*/ promptValid && state.userData.credits && state.userData.credits >= cost_per_veneer && (
            <Button
              disabled={models.has(chosenModel) && models.get(chosenModel).method != "text2vid" && !selectedReimagineItem}
              variant="contained"
              sx={{ marginLeft: "30px" }}
              onClick={createVeneerForTake}
            >
              {models.has(chosenModel) && models.get(chosenModel).method != "text2vid" ? "Reimagine" : "Create"}
            </Button>
          )
        }
      </DialogActions>
    </Dialog>
  );

  function runTest() {
    alert("all tests passed");
  }

  /*function OnFileUploadChange() {
    alert("OnFileUploadChange") 
  }*/

  function doInfo() {
    const msg =
      "Help us collect footage to make a video that follows this storyboard. Tap the image in any planned shot to upload a new take 1-6 seconds long. We prefer horizontal videos (cell phone sideways).  Thank you!";
    const msg2 = msg + `\n\n id: ${state.app.portal.id}`;
    alert(msg);
  }

  function doEdit() {
    //todo:  consider when the story selected is not last active project ...
    //dispatch({ type: "UPDATE_ACTIVE_STORY_REF" })
    // userRef.update({ lastActiveStory: activeStoryRef })
    history.push("/p/" + portal_id);
  }

  function clearSelectedShotAndItem() {
    setSelectedShot(null);
    setSelectedItem(null);
    setSelectedShotAction(null);
  }
  function clearSelectedPreview() {
    setSelectedPreview(null);
    setPreviewOpen(false);
  }
  function clearMoveModal() {
    setMoveModalOpen(null);
    setMoveTake(null);
  }
  function clearCompAlertModal() {
    setCompAlertOpen(null);
  }

  function clearReimagine() {
    setIsPromptCleared(false)
    setSelectedReimagineItem(null)
    setStrength(3)
    setSelectedMethod(reimagineDefaults.method)
    setChosenModel(reimagineDefaults.model)
    setShowReimagine(false)
  }

  function clearAuth() {
    setShowAuth(false);
  }

  //const sequence = computeSequence(story, shots, takesList)
  //const filmed = computePercentFilmed(story, shots, takes)

  function setSelectedShotAndAction(shot, action) {
    setSelectedShot(shot);
    setSelectedShotAction(action);
  }

  const last_cut_published = true; // move to portal.last_cut_published? or story.last_cut_published?
  const live_view_pct_threshold = 0.5; // move to portal.live_view_pct_threshold?
  // const live_draft_published = true // move to portal.live_draft_published?
  const show_last_cut = last_cut_published && "last_cut" in story && story.last_cut !== null;
  //const show_live_preview = !show_last_cut && (filmed['frac'] >= live_view_pct_threshold) && 'url' in sequence

  /*function setLastTake(take) {
    return new Promise((resolve, reject) => {
      console.dir(take)
      console.log('--------setlasttake')
      const takePayload = {"shot_id": take.shot_id, "last_take_id": take.id, "last_take_im": take.thumbnail, "last_take_stream_url": take.stream_path}
      dispatch({ type: "UPDATE_SHOT_LAST_TAKE", payload:takePayload })
      resolve('success')
    })
  }*/

  function setSelected(list) {
    let takes_list = list ? list : list == [] ? null : takesList;
    if (shot) {
      let initSelectedTake = null;
      // if any selection was made it takes precedence over the last take created
      if (shot.selected_take_id) {
        initSelectedTake = takes_list.filter((take) => take.id == shot.selected_take_id);
      } /*else if(shot.last_take_id) {
        initSelectedTake = takes_list.filter(take => take.id == shot.last_take_id);
      } */
      if (initSelectedTake && initSelectedTake.length) initSelectedTake = initSelectedTake[0];

      /*if((!initSelectedTake) && takes_list && takes_list.length) {
        //setLastTake(takes_list[takes_list.length - 1])
        initSelectedTake = takes_list[0]
      }*/
      if (initSelectedTake) {
        setSelectedItem(initSelectedTake);
        //selectItemForShot(initSelectedTake)
      }
      //else initSelectedTake = takes_list[0]

      /*if(initSelectedTake && initSelectedTake.streamPath) {
        selectItemForShot(initSelectedTake)
      }*/
      /*
      if(initSelectedTake && initSelectedTake.stream_path) {
        selectItemForShot(initSelectedTake)
      } else if(initSelectedTake == null) {
        setSelectedPreview(null)
        setSelectedItem(null)
        dispatch({ type: "UPDATE_SHOT_EMPTY_TAKE", payload: {shot_id: shot.id, image: random_image } });
      }*/
    }
  }

  setPrevNext();

  useEffect(() => {
    let takes_list = state.stories.activeTakeList ? state.stories.activeTakeList.filter((take) => take.shot_id === activeShotId) : [];
    if (deletingTake && takes.filter((take) => take.id === deletingTake.id).length === 0) {
      setDeletingTake(null);
    }
    if (moveTakeShot) {
      //setSelected(takes_list)
      setActiveTakes(takes_list);
      if (shot.last_take_id == null && takes_list.length) {
        const lastTake = takes_list[0];
        const prev_shot_selected_payload = {
          shot_id: shot.id,
          take_id: lastTake.id,
          image: lastTake.thumbnail,
          stream_path: lastTake.stream_path,
        };
        dispatch({ type: "UPDATE_SHOT_LAST_TAKE", payload: prev_shot_selected_payload });
      }
      if (!takes_list.length) {
        const no_selected_payload = {
          shot_id: shot.id,
          take_id: null,
          image: null,
          stream_path: null,
        };
        dispatch({ type: "UPDATE_SHOT_LAST_TAKE", payload: no_selected_payload });
      }
      takes_list = state.stories.activeTakeList.filter((take) => take.shot_id === moveTakeShot);
      //setActiveTakes(takes_list)
      //setSelected(takes_list)
      handleGo(moveTakeShot);
      setMoveTakeShot(null);
    }
    if (deletingTake && takes_list.findIndex((el) => el.id == deletingTake.id).length) {
      takes_list.splice(
        takes_list.findIndex((el) => el.id == deletingTake.id),
        1
      );
    }
    setTakes(state.stories.activeTakeList);
    setActiveTakes(takes_list);
    if (takes_list.length == 0) {
      setSelected([]);
    }
  }, [state.stories.activeTakeList]);

  const toggleShare = () => {
    setShowShareModal(!showShareModal)
  }

  const handleClosePreviewDialog = () => {
    setSelectedPreview(null); // Reset the selectedPreview state
  };

  return (
    <Container className="takes has-footbar" sx={{ maxWidth: "100% !important", paddingLeft: "0 !important", paddingRight: "0 !important" }}>
      {/*modified_sub_nav*/}
      {/*selectedShot && take_selector_modal*/}
      <Headbar title={shot?.header} text={shot?.description} shotId={shot?.id} close={goCancel} breadcrumbs={false} fork={true} share={true} />
      <Box sx={wrapStyles}>
        {selected_take && (
          <Box className={takesList ? "selected-wrap" : "empty-wrap"} sx={selectedWrapStyles}>
            <Box className="selected" sx={selectedStyles}>
              {takesList && selected_take}
            </Box>
            <Box className="reimagine" sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "30%" }}>
              {reimagine_cta}
            </Box>
          </Box>
        )}

        {!selected_take && (
          <Box className={takesList && takesList.length ? "selected-wrap" : "empty-wrap"} sx={selectedWrapStyles}>
            {takesList && (
              <Box sx={noSelectedTakesStyles}>
                <Typography variant="h2" sx={{ marginBottom:"1em" }}>{!takesList.length ? "Add a Take" : "All Takes" }</Typography>
                {takes_group}
              </Box>
            )}
            <Box className="reimagine" sx={{ display: "flex", alignItems: "center", width: "30%" }}>
              {reimagine_cta}
            </Box>
          </Box>
        )}
        {selected_take && takesList && (
          <Box sx={takesStyles}>
            <Typography variant="h2">All Takes</Typography>
            {takes_group}
          </Box>
        )}

        <ShareDialog width="330" open={showShareModal} toggleShare={toggleShare} />

        <TakesPreviewDialog 
          currentTakePreview={selectedPreview}
          handleClosePreviewDialog={handleClosePreviewDialog}
          setSelectedPreview={setSelectedPreview}
        >
        <DialogActions sx={{ display:'flex', flexDirection:'row', padding:'1em 2em', [theme.breakpoints.down('md')]: { marginBottom:"1rem !important" }, [theme.breakpoints.down('sm')]: { padding:'0 1em 1em', flexFlow:'column' }}}>
            {selectedPreview != null && 
              <>
              {selectedPreview.stream_path &&  (
              <>
                {selectedItem && state.app.userIsStoryOwner && isItemFromActiveShot(selectedItem) && selectedItem.id == selectedPreview.id && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      selectItemForShot(null);
                      handleTakeAway(e);
                    }}
                    sx={{
                      marginLeft: "25px",
                     "&:hover": { background: theme.palette.secondary.light },
                      [theme.breakpoints.down("sm")]: { marginLeft: "0", marginBottom: "1em", width: "100%" },
                    }}
                  >
                    <CheckBoxOutlineBlank sx={ iconStyles } />
                    <Typography variant="span">Unselect</Typography>
                  </Button>
                )}
                {(selectedItem === null || selectedItem.id != selectedPreview.id) && state.app.userIsStoryOwner && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      selectItemForShot(selectedPreview);
                    }}
                    sx={{
                      marginLeft: "25px",
                     "&:hover": { background: theme.palette.secondary.light },
                      [theme.breakpoints.down("sm")]: { marginLeft: "0", marginBottom: "1em", width: "100%" },
                    }}
                  >
                    <CheckBox sx={ iconStyles } />
                    <Typography variant="span">Select</Typography>
                  </Button>
                )}
                {shot?.last_take_stream_url && state.app.userIsStoryOwner && <Button variant="shadowed" onClick={(e) => { e.stopPropagation(); setMoveTake(selectedPreview); setMoveModalOpen(true); clearSelectedPreview(); e.preventDefault(); }}><OpenWith sx={iconStyles} /> <Typography variant="span">Move</Typography>
                        </Button>}
                {(state.app.userIsStoryOwner || state.app.settings.communityCanDownloadTakes) && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if(!isLoggedIn) handleAuth(e, ()=>downloadBlob(selectedPreview.video_path, getTakeFileName(shot, selectedPreview)))
                  else downloadBlob(selectedPreview.video_path, getTakeFileName(shot, selectedPreview));
                }}
                sx={{
                  marginLeft: "25px",
                 "&:hover": { background: theme.palette.secondary.light },
                  [theme.breakpoints.down("sm")]: { marginLeft: "0", marginBottom: "1em", width: "100%" },
                }}
              >
                <Download sx={iconStyles} /> <Typography variant="span">Download</Typography>
              </Button>
            )}
                {(state.app.userIsStoryOwner || state.app.settings.communityCanVeneer) && <Button variant="shadowed" onClick = {(e) => { isLoggedIn ? handleReimagine(e, selectedPreview) : handleAuth } }><MovieFilter sx={iconStyles} /> Reimagine</Button>}
            
                {(state.app.userIsStoryOwner || state.app.settings.communityCanDeleteTakes ) &&  (
                  <Button onClick={(e) => { 
                    e.stopPropagation(); 
                    if(!isLoggedIn) handleAuth(e, ()=>{ handleTakeDelete(selectedPreview, shot); clearSelectedPreview(); })
                    else { handleTakeDelete(selectedPreview, shot); clearSelectedPreview(); } 
                  }} sx={{ marginLeft:'25px', '&:hover': { background:theme.palette.secondary.light }, [theme.breakpoints.down('sm')]: { marginLeft:'0', marginBottom:'1em', width:'100%' } }}><Delete  sx={iconStyles} /> <Typography variant="span">Delete</Typography></Button>
                )}
              </>
            )}
          </>
        }
      </DialogActions>
        </TakesPreviewDialog>
        {move_modal}
        {comp_alert_modal}
        {reimagine_modal}
        {!isLoggedIn ? loginDialog : ""}
      </Box>
      {state.app.upload && (
        <div style={dynamicUploadStyles}>
          <AlertUploadStatus />
        </div>
      )}
      <Footbar buttons={[{ click: goCancel, type: "tertiary", text: "Cancel" }, prev, next]} />
    </Container>
  );
}

export default Takes;