import React, { useContext, useState, useEffect } from 'react';
import firebase, { auth, analytics } from "../utils/firebase.js";
import { logEvent } from "@firebase/analytics";
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult } from "@firebase/auth";
import { AuthContext } from "../utils/auth-provider";
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { Link, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import theme from "../styles/theme";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import AppContext from '../utils/app-context';
import EmailIcon from '@mui/icons-material/Email';
import assets from "../utils/assets"
import { set } from 'immer';

const DEFAULT_LOGIN_REDIRECT = "/new?login=true"

const VALUE_PROP_FOR_LOGIN = 'Log in to start a video project.'

const VALUE_PROP_FOR_LOGIN_MODAL = 'Log in to participate in the project.'

const mainStyles = {
    width: "100vw",
    height: "100vh",
    background: "#A9A9A9",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
        width: "100vw",
        height: "150vh",
        background: "#A9A9A9",
        margin: 0,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: "2vh",
    }
}
const modalStyles = {
    width: "100%",
    height: "auto",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center"
}
const linkStyles = {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: '500',
    fontSize: '14px',
    color: theme.palette.danger.main,
    textDecoration: 'none',
    marginLeft: '4px', 
    '&:hover': {
        textDecoration: 'underline',
    }
}


const headerBox = {
    flexBasis: '10 10 content',
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    margin: 'auto', 
    'img' : {
        height: "auto",
        width: "180px",
    }
}
const loginBox = {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: 'center',
    background: "#f5f5f5",
    borderRadius: "3px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: '95vw',
    height: '400px',
    maxWidth: '350px',
    // maxHeight: '600px',
}
const modalBox = {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: 'center',
    width: '95vw',
    height: '400px',
    maxWidth: '350px',
    hr: {
        display: 'none'
    }
    // maxHeight: '600px',
}
const linkBox = {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: 'center',
    background: "#f5f5f5",
    borderRadius: "3px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: '95vw',
    maxWidth: '350px',
    paddingTop: '25px',
    // maxHeight: '600px',
}
const forgotPasswordBox = {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: 'center',
    background: "#f5f5f5",
    borderRadius: "3px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: '95vw',
    height: '350px',
    maxWidth: '350px',
}
const resetPasswordBox = {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: 'center',
    background: "#f5f5f5",
    borderRadius: "3px",
    boxShadow: "0 0.5rem 1rem #808080",
    width: '95%',
    height: '250px',
    maxWidth: '350px',
}
const socialButtons = {
    textTransform: 'none',
    fontFamily: "'Roboto', sans-serif",
    fontWeight: '500',
    fontSize: '16px',
    width: '250px',
    height: '42px',
    margin: '5px',
    border: '1px solid #A9A9A9',
}
const form = {
    'form': {
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: 'center',
        marginBottom: '10px',
        fontFamily: "'Roboto', sans-serif",
        fontWeight: '500',
        fontSize: '14px',
    }
}
const inputs = {
    width: '250px',
    margin: '5px',
}
const submitButton = {
    width: '250px',
    margin: '10px 0',
}
const dividerText = {
    width: '20%',
    height: '1px',
    color: "#A9A9A9",
}
const dividerContainer = {
    width: '30%',
    margin: '0px 10px', 
    'hr': {
        width: '100%',
        height: '1px',
        color: "#A9A9A9",
        margin: '0px'
    }
}
const or = {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: '500',
    fontSize: '12px',
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0px'
}
const bottomPrompt = {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: '500',
    fontSize: '14px',
    color: '#A9A9A9',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '10px',
}
const footer = {
    width: '100%',
    marginTop: '10px',
    'hr' : {
        width: '100%',
        height: '1px',
        color: "#A9A9A9",
        margin: '0px'
    }
}
   

const getProviders = (user) => {
    const providers = new Set();
    if (!user) {
        return providers;
    }
    user.providerData.forEach((provider) => {
        providers.add(provider.providerId)
    })

    return providers;
}
  


export default function AuthBox(props) {

    const { currentUser } = useContext(AuthContext);
    const history = useHistory();
    const { state } = useContext(AppContext);
    const location = useLocation();
    const [redirectDone, setRedirectDone] = useState(null);
    const [authFuncDone, setAuthFuncDone] = useState(null);

    const redirect = props.redirect || null;
    const modal = props.version == 'login-modal' ? true : false
    let version = props.version ? (modal ? 'login' : props.version) : 'login';
    const value_prop = props.value_prop ? props.value_prop : VALUE_PROP_FOR_LOGIN
    const authFunc = props.authFunc || null;
    
    const [pageType, setPageType] = useState(version);

    //const auth = firebase.auth();

    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();
    //const emailProvider = new EmailAuthProvider();

    const [linkedProviders, setLinkedProviders] = useState(getProviders(currentUser));

    // Deal with unsuccessful login, such as sign in provider collision 
    useEffect(() => {
        getRedirectResult(auth).then((res) => {
        }).catch((error) => {
            alert(error.message)
        })
    }, [auth])

    // If redirected to login, set the path you came from as the redirect path after login completes
    if(redirect) {
        sessionStorage.setItem('afterLoginRedirect', redirect)
    } else if (location.state && location.state.from) {
        const redirectTo = `${location.state.from.pathname}${location.state.from.search}`
        sessionStorage.setItem('afterLoginRedirect', redirectTo)
    } 
    

    const gotoSignUp = (evt) => {
        evt.preventDefault();
        setPageType('signUp');
        history.push(`signup`)
    }

    const gotoLogin = (evt) => {
        evt.preventDefault();
        setPageType('login');
        history.push(`login`)
    }

    const gotoExample = (evt) => {
        evt.preventDefault();
        setPageType('example');
        history.push(`example`)
        logEvent(analytics, 'GO_TO_EXAMPLE');
    }

    const gotoForgotPassword = (evt) => {
        evt.preventDefault();
        setPageType('forgotPassword');
        history.push(`forgotPassword`)
    }

    const resetPassword = (evt) => {
        evt.preventDefault();
        const { email } = evt.target.elements;
        auth.sendPasswordResetEmail(email.value).then(function () {
            setPageType('passwordReset');
        }).catch(function (error) {
            alert(error.message);
        });
    }

    // TODO: handle errors
    const signInIdp = (provider) => () => {
        signInWithPopup(auth, provider).then(function () {
            history.push("/?login=true");
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);

            console.dir("login error", { 'code': errorCode, 'msg': errorMessage, 'mail': email, 'creds': credential })
        });
    }

    const linkGooglePhotos = (provider) => () => {
        provider.addScope('https://www.googleapis.com/auth/photoslibrary.readonly');
        provider.setCustomParameters({
            'login_hint': currentUser.email,
        })
        state.app.isMobile && auth.signInWithRedirect(provider)
        auth.signInWithPopup(provider).then((result) => {
            // what to do with the access token?
            // console.log(result.credential.accessToken)
        })
    }

    const linkFacebookPhotos = (provider) => () => {
        provider.addScope('user_photos');
        state.app.isMobile && auth.signInWithRedirect(provider)
        auth.signInWithPopup(provider).then((result) => {
            // what to do with the access token?
            // console.log(result.credential.accessToken)
        })
    }

    const linkIdp = (provider) => () => {
        state.app.isMobile && auth.currentUser.linkWithRedirect(provider)
        auth.currentUser.linkWithPopup(provider).then(() => {
            let newLinkedProviders = getProviders(currentUser)
            setLinkedProviders(newLinkedProviders);
        });
    }

    const unlinkIdp = (provider) => () => {
        auth.currentUser.unlink(provider.providerId).then(() => {
            let newLinkedProviders = getProviders(currentUser)
            setLinkedProviders(newLinkedProviders);
        });
    }

    const signInEmail = async (evt) => {
        evt.preventDefault();
        const { email, password } = evt.target.elements;
        try {
            await auth
                .signInWithEmailAndPassword(email.value, password.value);
            // TODO: take them to where they were originally trying to go
            history.push("/new?login=true");
        } catch (error) {
            alert(error);
        }
    }

    const signUpEmail = async (evt) => {
        evt.preventDefault();
        const { email, password } = evt.target.elements;
        try {
            await auth
                .createUserWithEmailAndPassword(email.value, password.value);
            auth.currentUser.sendEmailVerification().then(function () {
                // Email sent.
            }).catch(function (error) {
                console.log(error);
            });
            // TODO: take them to where they were originally trying to go
            history.push("/create/story");
        } catch (error) {
            alert(error);
        }
    }

    const linkEmail = async (evt) => {
        /*evt.preventDefault();
        const { email, password } = evt.target.elements;
        const credential = firebase.auth.EmailAuthProvider.credential(email.value, password.value);
        try {
            await auth
                .currentUser.linkWithCredential(credential);
            auth.currentUser.sendEmailVerification().then(function () {
                // Email sent.
            }).catch(function (error) {
                console.log(error);
            });
            // TODO: take them to where they were originally trying to go
            let newLinkedProviders = getProviders(currentUser)
            setLinkedProviders(newLinkedProviders);
        } catch (error) {
            alert(error);
        }*/
    }

        // If you came to the login page while logged in either 
    // 1. You were redirected after logging in with an identity provider
    // 2. you navigated here accidentally
    // 3. you were redirected here when logging out and the logout hasn't completed yet
    if (currentUser) {
        console.dir("login", currentUser)
        const redirectStorage = sessionStorage.getItem('afterLoginRedirect') ? sessionStorage.getItem('afterLoginRedirect').toString() : null
        console.dir("preredirect", redirect)
        if (location.state && location.state.logout) {
            // If we came from logging out, reset the redirect path
            console.log("---remove user state")
            sessionStorage.removeItem('afterLoginRedirect');
        } else if(state.userData && redirectStorage && redirectStorage !== null && redirectStorage != DEFAULT_LOGIN_REDIRECT) {
            console.log("---------settimeout")
            setTimeout(() => {
                console.log("----timeout done")
                // Otherwise, get the redirect path and redirect there or redirect to the default path
                console.dir("storage", sessionStorage.getItem('afterLoginRedirect'))
                //const loginRedirect = sessionStorage.getItem('afterLoginRedirect') ? sessionStorage.getItem('afterLoginRedirect').toString() : null
                //const redirectTo = redirect/* || DEFAULT_LOGIN_REDIRECT*/;
                console.dir("redirect to", redirect)
                console.dir(redirectStorage)
                console.dir(redirectDone)
                if(redirectStorage && location.pathname != redirectStorage) {
                    history.push(redirectStorage)
                    //setRedirectDone(true)
                    console.dir("redirect", redirectStorage)
                } else if(location.pathname == redirectStorage) {
                    sessionStorage.removeItem('afterLoginRedirect');
                    console.dir("redirect completed")
                }

                console.dir("loc", window.location.href)
                
                if(authFunc && authFuncDone === null) { 
                    console.dir(authFunc)
                    console.log("auth func")
                    setTimeout(() => {
                        authFunc()
                        setAuthFuncDone(true)
                    }, 1000);
                }
                //return <Redirect to={redirectTo} />;
            }, 500)
        }
    }

    //const rootClass = state.app.isMobile ? mobileRoot : root;

    if (pageType === 'linkPhotos') {

        return (
            <Box sx={mainStyles}>
                <div className="linkBox">
                    <Box sx={headerBox}>
                        <img alt="logo" src={assets["logo_vert.png"]} />
                    </Box>

                    <Button sx={socialButtons} onClick={linkGooglePhotos(googleProvider)} startIcon={<img src={assets["goog_logo.png"]} height="20px" width="20px" alt="f logo" />}>Connect Google Photos</Button>
                    <Button sx={socialButtons} onClick={linkFacebookPhotos(facebookProvider)} startIcon={<img src={assets["fb_logo.png"]} height="20px" width="20px" alt="g logo" />}>Connect Facebook Photos</Button>

                    <Box sx={footer} >
                        <hr />

                        <Box sx={bottomPrompt} >
                            Don't want to connect to your photos?
                            <Link sx={linkStyles} onClick={ gotoLogin() } href='#'>Go back</Link>
                        </Box>

                    </Box>

                </div>

            </Box>
        );
    }

    if (pageType === 'linkAccounts') {

        return (
            <Box sx={mainStyles}>
                <Box sx={linkBox}>
                    <Box sx={headerBox}>
                        <img  alt="logo" src={assets["logo_vert.png"]} />
                    </Box>
                    {linkedProviders.has(googleProvider.providerId) && <Button sx={socialButtons} onClick={unlinkIdp(googleProvider)} startIcon={<img src={assets["goog_logo.png"]} height="20px" width="20px" alt="f logo" />}>Unlink Google</Button>}
                    {!linkedProviders.has(googleProvider.providerId) && <Button sx={socialButtons} onClick={linkIdp(googleProvider)} startIcon={<img src={assets["goog_logo.png"]} height="20px" width="20px" alt="f logo" />}>Link Google</Button>}

                    {linkedProviders.has(facebookProvider.providerId) && <Button sx={socialButtons} onClick={unlinkIdp(facebookProvider)} startIcon={<img src={assets["fb_logo.png"]} height="20px" width="20px" alt="g logo" />}>Unlink Facebook</Button>}
                    {!linkedProviders.has(facebookProvider.providerId) && <Button sx={socialButtons} onClick={linkIdp(facebookProvider)} startIcon={<img src={assets["fb_logo.png"]} height="20px" width="20px" alt="g logo" />}>Link Facebook</Button>}


                    <Box sx={or} >
                        <Box sx={dividerContainer}><hr /></Box>
                        OR
                        <Box sx={dividerContainer}><hr /></Box>
                    </Box>

                    {/*linkedProviders.has(emailProvider.providerId) && <Button sx={socialButtons} onClick={unlinkIdp(emailProvider)} startIcon={<EmailIcon />}>Unlink Email/Password</Button>*/}

                    {/*!linkedProviders.has(emailProvider.providerId) && (
                        <Box sx={form}>
                            <form onSubmit={linkEmail}>
                                Add email and password login
                                <TextField sx={inputs} required id="email" type="email" label="Email" variant="outlined" />
                                <TextField sx={inputs} id="password" label="Password" type="password" variant="outlined" />
                                <Button sx={submitButton} type='submit' variant="contained" color="primary">Continue</Button>
                            </form>
                        </Box>
                    )*/
                    }

                    <Box sx={footer} >
                        <hr />

                        <Box sx={bottomPrompt} >
                            Don't want to link an account?
                            <Link sx={linkStyles} onClick={ gotoLogin() } href='#'>Go back</Link>
                        </Box>

                    </Box>

                </Box>

            </Box>
        );
    }

    if (pageType === 'forgotPassword') {
        return (
            <Box sx={mainStyles}>
                <Box sx={forgotPasswordBox}>
                    <Box sx={headerBox}>
                        <img  alt="logo" src={assets["logo_vert.png"]} />
                    </Box>
                    <Box sx={form}>
                        <form onSubmit={resetPassword}>
                            Enter your email
                        <TextField sx={inputs} required id="email" type="email" label="Email" variant="outlined" />
                            <Button sx={submitButton} type='submit' variant="contained" color="primary">Reset Password</Button>
                        </form>
                    </Box>

                    <Box sx={footer} >
                        <hr />

                        <Box sx={bottomPrompt} >
                            Remembered your password?
                            <Link sx={linkStyles} onClick={ gotoLogin() } href='#'>Log in</Link>
                        </Box>

                    </Box>

                </Box>

            </Box>
        )
    }


    if (pageType === 'passwordReset') {
        return (
            <Box sx={mainStyles}>
                <Box sx={resetPasswordBox}>
                    <Box sx={headerBox}>
                        <img  alt="logo" src={assets["logo_vert.png"]} />
                    </Box>
                    <Box sx={form}>
                        <form onSubmit={gotoLogin}>
                            Email sent. Check your inbox.
                        <Button sx={submitButton} type='submit' variant="contained" color="primary">Go to Login</Button>
                        </form>
                    </Box>

                </Box>

            </Box>
        )
    }


    return (
        <Box sx={(modal) ? modalStyles : mainStyles}>
            <Box sx={(modal) ? modalBox : loginBox}>
                <Box sx={headerBox}>
                    <img  alt="logo" src={assets["logo_vert.png"]} />
                </Box>
                {value_prop}

                <Button sx={socialButtons} onClick={signInIdp(googleProvider)} startIcon={<img src={assets["goog_logo.png"]} height="20px" width="20px" alt="f logo" />}>Continue with Google</Button>
                {/* <Button className={socialButtons} onClick={signInIdp(facebookProvider)} startIcon={<img src={assets["fb_logo.png"]} height="20px" width="20px" alt="g logo" />}>Continue with Facebook</Button> 

                <div className={or} >
                    <div className={dividerContainer}><hr className={dividerFull} /></div>
                    OR
                    <div className={dividerContainer}><hr className={dividerFull} /></div>
                </div>

                <form className={form} onSubmit={pageType === 'login' ? signInEmail : signUpEmail}>
                    {pageType === 'login' && "Log in with email"}
                    {pageType === 'signUp' && "Sign up with email"}
                    <TextField className={inputs} required id="email" label="Email" variant="outlined" />
                    <TextField className={inputs} id="password" label="Password" type="password" variant="outlined" />
                    <Button className={submitButton} type='submit' variant="contained" color="primary">Continue</Button>
                    {pageType === 'login' && <Link className={Link} onClick={gotoForgotPassword} href='#'>Forgot password?</Link>}
                </form> */}

                <Box sx={footer} >
                    <hr />

                    {/*<Box sx={bottomPrompt} >
                        Just curious? Preview an 
                        <Link sx={linkStyles} onClick={gotoExample} href='#'>example</Link>
                        .
            </Box>*/}
                    {/*<div className={bottomPrompt} >
                        {pageType === 'login' && "Don't have an account?"}
                        {pageType === 'login' && <Link className={Link} onClick={gotoSignUp} href='#'>Sign Up</Link>}
                        {pageType === 'signUp' && "Already have an account?"}
                        {pageType === 'signUp' && <Link className={Link} onClick={gotoLogin} href='#'>Log in</Link>}
                    </div> */}

                </Box>

            </Box>

        </Box>
    );
}
