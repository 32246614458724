import { getApp } from "@firebase/app";
import { getStripePayments, getProducts, createCheckoutSession, onCurrentUserSubscriptionUpdate } from "@invertase/firestore-stripe-payments";
import config from './config'
//import { useHistory } from "react-router-dom";

const app = getApp();
//const history = useHistory ();

const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
});
console.dir(payments);

export const products = async (type) => {
  console.dir('123')
  const recurring = type == 'recurring' ? true : false;
  //let productList = [];
  
  const products = await getProducts(payments, {
    includePrices: true,
    activeOnly: true,
  });
  console.dir("products stripe", products);
  const productList = products.filter(product => (recurring ? product.prices[0].type == 'recurring' : product.prices[0].type == 'one_time'))
  /*for(const product of products) {
    if(recurring && )
      productList.push(product)
  }*/
  
  console.dir("product list", productList)
  return productList;
}

export const checkout = async(user, product, quantity) => {
  console.dir("product", product)
  console.dir("payments", payments)
  const app_url = config.app_url;
  const session = await createCheckoutSession(payments, {
    price: product.prices[0].id,
    quantity: quantity,
    client_reference_id: user.uid,
    mode: "payment", 
    success_url: app_url+"/storyboards",
  })
  console.dir('checkout session', session)
  //history.push(session.url);
  console.dir("session", session);
  return session.url
}


//const updates = () => {}
/*onCurrentUserSubscriptionUpdate(
  payments,
  (snapshot) => {
    for (const change of snapshot.changes) {
      if (change.type === "added") {
        console.log(`New subscription added with ID: ${change.subscription.id}`);
      }
    }
  }
);*/