import { 
    Link, 
    Typography, 
    Container, 
    Box, 
    Button, 
  } from '@mui/material';
  import React, { useContext, useEffect, useState } from "react";
  import { Helmet } from 'react-helmet';
  import AppContext from "../utils/app-context";
  import { AuthContext } from "../utils/auth-provider";
  import theme from '../styles/theme'
  import Headbar from "./Headbar";
  import Footbar from "./Footbar";
  import { products, checkout } from "../utils/stripe";
  
  function Plan() {
    const { state, dispatch } = useContext(AppContext);
    const { currentUser } = useContext(AuthContext);
    const [productList, setProductList] = useState([]);
    const [plan, setPlan] = useState([]);
    const currPlan = state.userData.account;
    
    const wrapStyles = {
      padding: "3em",
      [theme.breakpoints.down("md")]: {
        padding: "1.5em",
      },
    };

    const selectPlan = (product) => {
      //alert('select')
      if(product) {
        checkout(product).then(url => {
          window.location.assign(url)
        })
      }
    }

    //if(!productList.length) {
    useEffect(() => {
      products('recurring').then(items => {
        items.unshift({
          id: "free", 
          name: "Free", 
          description: "A free plan for exploring 10Zebra.", 
          prices: "123"
        })
        //if(items.length) setProductList(items.filter(item => item.id == "free" || (item.prices.length && item.prices[0].type == "recurring")));
        setProductList(items);
      });
    }, [])

    useEffect(() => {  
      const sub = productList.filter(item => item.id == plan)[0]
      console.dir(sub)
      if(sub) selectPlan(sub)
    }, [plan])
    
    console.dir("list", productList)
    return (
      <Container style={{ maxWidth:'100%', paddingLeft:0, paddingRight:0 }}>
        <Headbar title="Plans" admin={true} />
        <Box sx={wrapStyles}>
          <Helmet>
            <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
          </Helmet>
          <stripe-pricing-table 
            pricing-table-id="prctbl_1QNLI2Rpp3is5HHGEvTgHuvL"
            publishable-key="pk_test_51PX1XtRpp3is5HHGbksfivniXjCWMeW2LwreaZqlkQbryCKiRovkZCi3Zmc2WIQSyi4NbIG1Z01sihHvJvGSb8aJ00QoMVyLad"
            client-reference-id={ currentUser?.uid }>
          </stripe-pricing-table>
        </Box>
      </Container>
    );
  }
  
  export default Plan;